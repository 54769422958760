import React, { useRef, Fragment } from 'react'
import Confetti from 'react-confetti'
import ThemedModal from '../../ThemedModal'
import { Modal } from 'rsuite'
import { getNetworkName, WEB3_LOCK_TYPE, WEB3_NETWORK_NAMES } from 'util/web3'
import {
  ModalText,
  ModalError,
  ModalHeader,
  StakingLoader,
  StakingTransaction,
  StakingModalWrapper
} from './components/index'

interface StakeStatusDialogProps {
  token: string
  staking: boolean
  networkId: number
  transactionHash: string
  lockType: WEB3_LOCK_TYPE
  stakingModalError: string
  stakingSucceeded: boolean
  confirmationNumber: number
  showStakeStatusDialog: boolean
  setShowStakeStatusDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const StakeStatusDialog = ({
  token,
  staking,
  lockType,
  networkId,
  transactionHash,
  stakingSucceeded,
  stakingModalError,
  confirmationNumber,
  showStakeStatusDialog,
  setShowStakeStatusDialog
}: StakeStatusDialogProps) => {
  const networkName = getNetworkName(networkId)
  const subdomain = networkName === WEB3_NETWORK_NAMES.MAINNET ? '' : `${networkName}.`
  const href = `https://${subdomain}etherscan.io/tx/${transactionHash}`

  const modalRef = useRef<HTMLDivElement>(null)

  const width = modalRef?.current?.clientWidth || 560
  const height = modalRef?.current?.clientHeight || 240

  let header
  let text
  if (staking) {
    header = 'Waiting for your confirmation'
    text = (
      <Fragment>
        Please Confirm the transaction in your wallet
        <br />
        <StakingLoader size='lg' />
      </Fragment>
    )
  } else {
    if (confirmationNumber === 0) {
      if (stakingSucceeded) {
        header = 'Success!'
        text = (
          <Fragment>
            Transaction has been successful!
            <br />
            Your {token} Staking balance has been updated.
          </Fragment>
        )
      } else {
        header = 'Standby'
        text = (
          <Fragment>
            The transaction has been confirmed, please wait and do not close this tab.
            <br />
            <StakingLoader size='lg' />
          </Fragment>
        )
      }
    }
  }

  return (
    <ThemedModal overflow={false} show={showStakeStatusDialog}>
      <div ref={modalRef}>
        <Modal.Header onHide={() => setShowStakeStatusDialog(false)}>
          <ModalHeader>{stakingModalError ? `That didn't work` : header}</ModalHeader>
        </Modal.Header>
        <Modal.Body>
          <StakingModalWrapper>
            {stakingModalError ? (
              <Fragment>
                <ModalText>
                  Please return to StormX.
                  <br />
                  If this problem continues please get in touch with our support.
                </ModalText>
                <ModalError>{stakingModalError}</ModalError>
              </Fragment>
            ) : (
              <ModalText>{text}</ModalText>
            )}
            {transactionHash && (
              <StakingTransaction>
                <a href={href} rel='noopener noreferrer' target='_blank'>
                  View the transaction on etherscan
                </a>
              </StakingTransaction>
            )}
          </StakingModalWrapper>
        </Modal.Body>

        {stakingSucceeded && lockType === WEB3_LOCK_TYPE.LOCK && (
          <Confetti width={width + 40} height={height + 40} numberOfPieces={50} />
        )}
      </div>
    </ThemedModal>
  )
}

export default StakeStatusDialog
