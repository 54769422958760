import React from 'react'
import styled from 'styled-components'

import { responsiveWidthS, responsiveWidthM } from 'util/const'

interface BoxProps {
  completed?: boolean
  twoRowsOnMobile?: boolean
}

interface ProgressBoxProps {
  text: React.ReactNode
  completed?: boolean
  previousDone?: boolean
  twoRowsOnMobile?: boolean
}

const BoxWrapper = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    ${({ twoRowsOnMobile }: BoxProps) => (twoRowsOnMobile ? 'padding-top: 110px;' : '')}
  }

  position: relative;
  padding-top: 60px;
`

const Box = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    ${({ twoRowsOnMobile }: BoxProps) => (twoRowsOnMobile ? 'height: 120px;' : '')}
  }

  @media only screen and (max-width: ${responsiveWidthS}px) {
    background: transparent;
    box-shadow: none;
    padding: 23px 0;
    height: auto;
  }

  ${({ completed }: BoxProps) => (completed ? 'opacity: 0.5;' : '')}
  background: rgb(255, 255, 255);
  border-radius: 12px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  color: rgb(0, 0, 0);
  font-family: Adelle;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0px;
  padding: 20px 25px;
  height: 70px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
`

const BoxChildren = styled.div`
  @media only screen and (max-width: ${responsiveWidthS}px) {
    background: none;
    padding: 0;
  }

  background: rgba(86, 96, 105, 0.05);
  border-radius: 0px 0px 8px 8px;
  padding: 30px;
`

const ProgressBox = ({
  children,
  completed,
  previousDone,
  text,
  twoRowsOnMobile
}: React.PropsWithChildren<ProgressBoxProps>) => (
  <BoxWrapper twoRowsOnMobile={twoRowsOnMobile}>
    <Box completed={completed} twoRowsOnMobile={twoRowsOnMobile}>
      {text}
    </Box>
    {children && !completed && previousDone ? <BoxChildren>{children}</BoxChildren> : null}
  </BoxWrapper>
)

export default ProgressBox
