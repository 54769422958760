import styled from 'styled-components'
import { withModifiers } from 'util/styles'
import { responsiveWidthM } from 'util/const'

interface TextProps {
  line?: boolean
  terms?: boolean
  signOut?: boolean
  subtitle?: boolean
  underline?: boolean
  pageTitle?: boolean
  swapTitle?: boolean
  swapDescription?: boolean
}

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('pageTitle')`
    color: rgb(255, 255, 255);
    font-size: 38px;
    font-family: Montserrat;
    font-weight: 800;
    text-align: center;
    width: 100%;
    display: flex;
    margin: 0 auto;
  `};

  ${withModifiers('subtitle')`
    color: rgb(255, 255, 255);
    font-size: 18px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
    line-height: 26px;
  `};

  ${withModifiers('underline')`
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  `};

  ${withModifiers('swapTitle')`
    color: rgb(29, 29, 29);
    font-size: 22px;
    font-family: Montserrat;
    font-weight: 800;
    text-align: center;
    line-height: 34px;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 20px;
  `};

  ${withModifiers('swapDescription')`
    color: rgb(29, 29, 29);
    font-size: 16px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.15px;
    line-height: 24px;
    margin-left: 10%;
    margin-right: 10%;
  `};

  ${withModifiers('terms')`
    color: rgb(86, 96, 105);
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
    text-align: center;
    line-height: 16px;
  `};

  ${withModifiers('line')`
    margin-left: 3px;
    margin-right: 3px;
  `};

  ${withModifiers('signOut')`
    color: rgb(250, 46, 83);
    font-size: 14px;
    font-family: Adelle;
    font-weight: normal;
    letter-spacing: 0px;
    margin-top: 24px;
    margin-bottom: 20px;
  `};
`

export const Title = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 10px;
    padding-right: 10px;
  }

  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: 38px;
  font-weight: 800;
`

export const BottomCenter = styled.div`
  position: absolute;
  margin-top: 100px;
  width: 100%;
  padding-bottom: 40px;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TextContainer = styled.div`
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  display: flex;
`
