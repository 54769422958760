import { Tooltip } from 'rsuite'
import styled from 'styled-components'

export default styled(Tooltip)`
  & > .rs-tooltip-arrow {
    border-bottom-color: rgb(255, 255, 255) !important;
  }

  & > .rs-tooltip-inner {
    background: rgb(255, 255, 255);
    border-radius: 8px;
    box-shadow: 0px 1px 18px 0px rgba(0, 0, 0, 0.05), 0px 4px 41px 0px rgba(0, 0, 0, 0.03);
    color: rgb(44, 37, 60);
    font-family: Adelle;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 20px;
    padding: 30px 20px;
    text-align: left;
  }
`
