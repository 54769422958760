import React, { Fragment } from 'react'
import api from 'util/api'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { web3State, authSelector, accountState, Web3StateType } from 'util/state'
import {
  Text,
  RowContainer,
  MetaMaskIcon,
  DisconnectButton,
  WalletConnectIcon,
  ConnectedContainer
} from './components/index'

const Wallet = () => {
  const setAuth = useSetRecoilState(authSelector)
  const setAuthAccount = useSetRecoilState(accountState)
  const web3state = useRecoilValue(web3State)
  const isMetamaskConnected = web3state.type === Web3StateType.NATIVE
  const wallet = web3state.wallet

  const logout = async () => {
    await api.get('/signOut')
    setAuth({
      signedIn: false,
      token: undefined
    })
    setAuthAccount({ loading: false })
  }

  return (
    <Fragment>
      <ConnectedContainer>
        <RowContainer>
          {isMetamaskConnected ? <MetaMaskIcon /> : <WalletConnectIcon />}
          <RowContainer column>
            <Text wallet>{`${wallet?.slice(0, 4)}...${wallet?.slice(-4)}`}</Text>
            <Text connectionType>{isMetamaskConnected ? 'MetaMask' : 'WalletConnect'}</Text>
          </RowContainer>
        </RowContainer>

        <DisconnectButton onClick={() => logout()}>
          <Text disconnect>Disconnect</Text>
        </DisconnectButton>
      </ConnectedContainer>
    </Fragment>
  )
}

export default Wallet
