import React, { Fragment } from 'react'
import ThemedModal from '../../ThemedModal'
import { detection } from 'util/browser'
import { Modal } from 'rsuite'
import { iOSApp, androidApp, EXTENSION_URL, openMobileAppDeeplink } from 'util/const'
import {
  QRCode,
  ModalHeader,
  BrowserEdge,
  RowContainer,
  ModalContent,
  BrowserChrome,
  AppleStoreButton,
  GooglePlayButton
} from './components/index'

interface WithdrawDialogProps {
  showWithdraw: boolean
  isMobileBrowser: boolean
  setShowWithdraw: (show: boolean) => void
}

const WithdrawDialog = ({ showWithdraw, setShowWithdraw, isMobileBrowser }: WithdrawDialogProps) => {
  return (
    <ThemedModal overflow={false} show={showWithdraw}>
      <Modal.Header onHide={() => setShowWithdraw(false)}>
        <ModalHeader>Withdraw</ModalHeader>
      </Modal.Header>
      <Modal.Body>
        {isMobileBrowser ? (
          <ModalContent>
            To complete the withdrawal download and sign in to the <a href={openMobileAppDeeplink}>StormX app</a>.
          </ModalContent>
        ) : (
          <Fragment>
            <ModalContent>To complete the withdrawal download and sign in to an app.</ModalContent>

            <ModalContent>Desktop extension</ModalContent>
            <ModalContent
              onClick={() => {
                window.open(EXTENSION_URL)
              }}
            >
              {detection.isChrome ? <BrowserChrome /> : <BrowserEdge />}
            </ModalContent>

            <ModalContent>Mobile</ModalContent>

            <RowContainer>
              <RowContainer>
                <GooglePlayButton
                  onClick={() => {
                    window.open(androidApp)
                  }}
                />
                <AppleStoreButton
                  onClick={() => {
                    window.open(iOSApp)
                  }}
                />
              </RowContainer>

              <QRCode />
            </RowContainer>
          </Fragment>
        )}
      </Modal.Body>
    </ThemedModal>
  )
}

export default WithdrawDialog
