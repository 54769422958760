import React, { useState, Fragment } from 'react'
import api from 'util/api'
import BuyModal from '../buy-modal/index'
import Wallet from '../wallet'
import { isMobile } from 'react-device-detect'
import { useMediaQuery } from 'react-responsive'
import { responsiveWidthM, SWAP_URL } from 'util/const'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { web3State, authSelector, accountState } from 'util/state'
import { Text, Logo, RowContainer } from './components/index'

const Header = () => {
  const setAuth = useSetRecoilState(authSelector)
  const [showBuyStmxModal, setBuyStmxModal] = useState(false)
  const [authAccount, setAuthAccount] = useRecoilState(accountState)
  const web3state = useRecoilValue(web3State)
  const wallet = web3state.wallet
  const isSmallScreen = useMediaQuery({
    query: `(max-width: ${responsiveWidthM}px)`
  })

  const logout = async () => {
    await api.get('/signOut')
    setAuth({
      signedIn: false,
      token: undefined
    })
    setAuthAccount({ loading: false })
  }

  return (
    <Fragment>
      <RowContainer withSpaceBetween wrapper smallScreen={isSmallScreen}>
        <Logo />

        <RowContainer right>
          {!isMobile && !isSmallScreen && (
            <Fragment>
              <Text link onClick={() => window.open(SWAP_URL)}>
                Token Swap
              </Text>

              <Text link onClick={() => setBuyStmxModal(true)}>
                Buy STMX
              </Text>

              <Wallet />
            </Fragment>
          )}

          {(isMobile || isSmallScreen) && (
            <Text link onClick={() => logout()}>
              Sign out
            </Text>
          )}
        </RowContainer>
      </RowContainer>

      <BuyModal
        accountId={authAccount?.account?.id}
        email={authAccount?.account?.email as string}
        setShowModal={setBuyStmxModal}
        show={showBuyStmxModal}
        walletAddress={wallet}
      />
    </Fragment>
  )
}

export default Header
