import { WEB_PLATFORM_AFF_ID } from 'util/const'
import { User } from 'util/types'
import { clearLocationHash, parseParams, getRandomUserId, removeSpecialChars } from 'util/utils'

export interface AccountState {
  account?: User
  loading?: boolean
}

export interface AuthState {
  'aff-id'?: number
  cached?: boolean
  devicename?: string
  notLoaded?: boolean
  signedIn?: boolean
  token?: string
  udid?: string
  version?: string
}

export const DEFAULT_ACCOUNT: AccountState = {}

export const DEFAULT_AUTH: AuthState = {
  'aff-id': WEB_PLATFORM_AFF_ID,
  cached: false,
  devicename: removeSpecialChars(navigator.appCodeName),
  notLoaded: false,
  signedIn: false,
  token: undefined,
  udid: getRandomUserId(),
  version: '0.0.1'
}

export const parseHashAuth = (): AuthState => {
  const hashParams = parseParams(window.location.hash)

  const hasRequiredParams = !!(
    hashParams['aff-id'] &&
    hashParams['devicename'] &&
    hashParams['token'] &&
    hashParams['udid'] &&
    hashParams['version']
  )

  let hashAuth = {}
  if (hasRequiredParams) {
    hashAuth = {
      'aff-id': parseInt(decodeURIComponent(hashParams['aff-id'])),
      devicename: decodeURIComponent(hashParams['devicename']),
      token: decodeURIComponent(hashParams['token']),
      udid: decodeURIComponent(hashParams['udid']),
      version: decodeURIComponent(hashParams['version'])
    }

    clearLocationHash()
  }

  return {
    ...DEFAULT_AUTH,
    ...hashAuth,
    cached: hasRequiredParams,
    signedIn: hasRequiredParams
  }
}
