import React, { useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { Button, Loader, Modal } from 'rsuite'
import styled from 'styled-components'
import isString from 'lodash/isString'

import api from 'util/api'
import { responsiveWidthS } from 'util/const'
import { obfuscateWallet } from 'util/utils'
import { accountState, authSelector } from 'util/state'

import ThemedModal from '../ThemedModal'

import MetaMaskImage from '../images/icon-metamask.svg'
import TrustWalletImage from '../images/icon-trust-wallet.svg'
import ArrowLeftImage from '../images/img-arrow-left.svg'

interface ProblemsConnectingModalProps {
  error: boolean | string
  isMobileBrowser: boolean
  usedWallet?: string
  wallet?: string
}

enum ModalState {
  INIT = 0,
  TRUST_WALLET = 1
}

const ModalHeader = styled.div`
  &:before {
    content: ' ';
    background-color: #dfe1e2;
    display: inline-block;
    height: 1px;
    position: absolute;
    right: 80px;
    bottom: 0;
    left: 100px;
  }

  color: rgb(44, 37, 60);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 19px;
  text-align: center;
  margin-top: 30px;
  position: relative;
  box-sizing: content-box;
`

const ModalBack = styled.div`
  background: transparent url(${ArrowLeftImage}) 0 0 no-repeat;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
  padding: 5px;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  margin: 5px 0 0 5px;
`

const ModalText = styled.div`
  @media only screen and (max-width: ${responsiveWidthS}px) {
    margin-right: 25px;
    margin-left: 25px;
  }

  color: rgb(44, 37, 60);
  font-family: Adelle;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 24px;
  text-align: center;
  margin: 0 45px 10px 45px;
`

const ModalTextMarginBottom = styled(ModalText)`
  margin-bottom: 25px;
`

const ModalTextFAQ = styled(ModalText)`
  margin-top: 5px;
`

const ModalTitle = styled.div`
  color: rgb(44, 37, 60);
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  margin: -5px 0 10px 0;
`

const ModalTitleMarginTop = styled(ModalTitle)`
  margin-top: 10px;
`

const ModalWrapper = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
  min-height: 50px;
  text-align: center;
`

const ImportButton = styled(Button)`
  border-radius: 30px;
  border: 1px solid rgb(255, 0, 77);
  color: rgb(255, 0, 77);
  height: 38px;
  background-color: rgb(255, 255, 255);
  margin-top: 15px;
  margin-bottom: 45px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  padding: 0 30px;
`

const ModalDivider = styled.div`
  &:before {
    content: ' ';
    background-color: #dfe1e2;
    display: inline-block;
    height: 1px;
    position: absolute;
    right: 80px;
    top: 25px;
    left: 100px;
  }

  position: relative;
  height: 60px;
`

const ModalDividerShorter = styled(ModalDivider)`
  &:before {
    content: ' ';
    background-color: #dfe1e2;
    display: inline-block;
    height: 1px;
    position: absolute;
    right: 80px;
    top: 15px;
    left: 100px;
  }

  height: 30px;
`

const SignoutButton = styled(Button)`
  margin-top: -25px;
  text-decoration: underline;
`

const TrustWalletIcon = styled.img.attrs({ src: TrustWalletImage })`
  height: 64px;
  margin: 5px 15px 20px 15px;
`

const MetaMaskIcon = styled.img.attrs({ src: MetaMaskImage })`
  height: 60px;
  margin: 5px 15px 20px 15px;
`

const RedBox = styled.div`
  background: rgb(250, 46, 83);
  border-radius: 8px;
  border: 1px solid rgba(56, 56, 56, 0.1);
  height: 40px;
  width: 40px;
  margin: 0 auto;
  color: rgb(255, 255, 255);
  font-family: Adelle;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 40px;
  width: 40px;
  text-align: center;
`

const RedBoxStep2 = styled(RedBox)`
  margin-top: -47px;
`

const Step2Wrapper = styled.div`
  @media only screen and (max-width: ${responsiveWidthS}px) {
    margin-right: 0;
    margin-left: 0;
  }

  border-radius: 18px;
  border: 2px solid rgb(250, 46, 83);
  margin: 55px 30px 30px 30px;
  padding: 25px 0 20px 0;
`

const DownloadMetaMaskLink = styled.a.attrs({
  href: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  color: rgb(253, 46, 83);
`

const FAQLink = styled.a.attrs({
  href: 'https://help.stormx.io/hc/en-us/articles/1500000250481-How-to-import-your-Trust-Wallet-into-MetaMask-on-Chrome-browser',
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  color: rgb(253, 46, 83);
`

const ProblemsConectingModal = ({ error, isMobileBrowser, usedWallet, wallet }: ProblemsConnectingModalProps) => {
  const setAuth = useSetRecoilState(authSelector)
  const setAuthAccount = useSetRecoilState(accountState)

  const [modalState, setModalState] = useState(ModalState.INIT)

  const logout = async () => {
    await api.get('/signOut')
    setAuth({
      signedIn: false,
      token: undefined
    })
    setAuthAccount({ loading: false })
  }

  let header
  let content
  if (isString(error)) {
    header = (
      <Modal.Header closeButton={false}>
        <ModalHeader>Oops, that didn't work</ModalHeader>
      </Modal.Header>
    )
    content = (
      <ModalWrapper>
        <ModalText>{error}</ModalText>
      </ModalWrapper>
    )
  } else if (error === true) {
    if (modalState === ModalState.INIT) {
      if (isMobileBrowser) {
        header = (
          <Modal.Header closeButton={false}>
            <ModalHeader>Switch your wallet address.</ModalHeader>
          </Modal.Header>
        )
        content = (
          <ModalWrapper>
            <MetaMaskIcon />
            <TrustWalletIcon />
            <ModalText>
              Open the MetaMask or Trust Wallet mobile app and select the wallet address:{' '}
              <b>{wallet ? `${obfuscateWallet(wallet)}` : ''}</b>
              <br />
              You are currently using: <b>{usedWallet ? obfuscateWallet(usedWallet) : 'None. Check you wallet.'}</b>
              <br />
              If the problem persists please try it on a different browser.
            </ModalText>
          </ModalWrapper>
        )
      } else {
        header = (
          <Modal.Header closeButton={false}>
            <ModalHeader>We're having problems connecting to your wallet.</ModalHeader>
          </Modal.Header>
        )
        content = (
          <ModalWrapper>
            <MetaMaskIcon />
            <ModalTitle>Are you using MetaMask?</ModalTitle>
            <ModalText>
              Open the MetaMask extension and select the wallet address:{' '}
              <b>{wallet ? `${obfuscateWallet(wallet)}` : ''}</b>
              <br />
              You are currently using: <b>{usedWallet ? obfuscateWallet(usedWallet) : 'None. Check you wallet.'}</b>
            </ModalText>
            <ModalDivider />
            <TrustWalletIcon />
            <ModalTitle>Are you using Trust Wallet?</ModalTitle>
            <ModalText>
              Mobile staking with Trust Wallet is not officially supported yet. If you still want to proceed, you'll
              need to import your seed phrase into MetaMask.
            </ModalText>
            <ImportButton appearance='primary' onClick={() => setModalState(ModalState.TRUST_WALLET)} type='submit'>
              Continue with import
            </ImportButton>
            <ModalText>Wrong StormX account?</ModalText>
            <SignoutButton appearance='link' onClick={() => logout()}>
              Sign out
            </SignoutButton>
          </ModalWrapper>
        )
      }
    } else if (modalState === ModalState.TRUST_WALLET) {
      header = (
        <Modal.Header closeButton={false}>
          <ModalBack onClick={() => setModalState(ModalState.INIT)}></ModalBack>
          <ModalHeader>Importing your wallet</ModalHeader>
        </Modal.Header>
      )
      content = (
        <ModalWrapper>
          <RedBox>1</RedBox>
          <ModalTitleMarginTop>Copy Recovery Phrase</ModalTitleMarginTop>
          <ModalTextMarginBottom>
            Open Trust Wallet. Click on Settings. Click on the Info icon on your account. Click on Show Recovery Phrase.
          </ModalTextMarginBottom>
          <Step2Wrapper>
            <RedBoxStep2>2</RedBoxStep2>
            <ModalTitleMarginTop>I don’t have MetaMask</ModalTitleMarginTop>
            <ModalText>
              <DownloadMetaMaskLink>Download MetaMask</DownloadMetaMaskLink>. At the bottom click on Import using
              account seed phrase. Enter your Trust Wallet phrase.
            </ModalText>
            <ModalDividerShorter />
            <ModalTitle>I have MetaMask installed</ModalTitle>
            <ModalText>
              1. Backup your wallet first
              <br />
              2. Click the circle button in the top right and select "Lock"
              <br />
              3. Select "Import using account seed phrase"
              <br />
              4. Enter your Trust Wallet phrase.
            </ModalText>
          </Step2Wrapper>
          <ModalTextFAQ>
            For more in-depth instructions check our <FAQLink>FAQ</FAQLink>.
          </ModalTextFAQ>
        </ModalWrapper>
      )
    }
  } else {
    header = (
      <Modal.Header closeButton={false}>
        <ModalHeader>Oops, that didn't work</ModalHeader>
      </Modal.Header>
    )
    content = (
      <ModalWrapper>
        <Loader />
      </ModalWrapper>
    )
  }

  return (
    <ThemedModal overflow={false} show={!!error}>
      {header}
      <Modal.Body>{content}</Modal.Body>
    </ThemedModal>
  )
}

export default ProblemsConectingModal
