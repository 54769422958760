import styled from 'styled-components'
import metamaskIcon from 'components/staking/images/metamask-icon.png'
import walletconnectIcon from 'components/staking/images/walletconnect-icon.png'
import { withModifiers } from 'util/styles'

interface TextProps {
  link?: boolean
  wallet?: boolean
  buy?: boolean
  disconnect?: boolean
  connectionType?: boolean
}

interface RowContainerProps {
  column?: boolean
  withSpaceBetween?: boolean
}

export const RowContainer = styled.div<RowContainerProps>`
  flex-direction: row;
  align-items: center;
  display: flex;

  ${withModifiers('withSpaceBetween')`
    justify-content: space-between;
  `};

  ${withModifiers('column')`
    flex-direction: column;
    align-items: flex-start;
  `};
`

export const WalletConnectIcon = styled.img.attrs({
  src: walletconnectIcon
})`
  height: 38px;
  width: 38px;
  margin-right: 11px;
`

export const MetaMaskIcon = styled.img.attrs({
  src: metamaskIcon
})`
  height: 38px;
  width: 38px;
  margin-right: 11px;
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('disconnect')`
    color: rgb(250, 46, 83);
    font-size: 13px;
    font-family: Montserrat;
    font-weight: 600;
    text-align: center;
    line-height: 16px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  `};

  ${withModifiers('wallet')`
    color: rgb(29, 29, 29);
    font-size: 14px;
    font-family: Adelle;
    font-weight: normal;
    text-decoration: underline;
    letter-spacing: 0.2px;
    line-height: 20px;
  `};

  ${withModifiers('connectionType')`
    color: rgb(86, 96, 105);
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 20px;
  `};
`

export const ConnectedContainer = styled.div`
  background: rgb(255, 255, 255);
  border: 1px solid rgb(231, 231, 231);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  border-radius: 32px;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 9px;
  padding-bottom: 9px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 300px;
`

export const DisconnectButton = styled.div`
  width: 110px;
  height: 30px;
  border: 1px solid rgb(253, 46, 83);
  border-radius: 17px;
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`
