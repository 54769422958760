import React, { Fragment } from 'react'
import { RowContainer, ToggleWrapper, ToggleCurrency, ToggleCurrencyItem } from './components/index'

interface SwitcherProps {
  stmxSelected: boolean
  setStmxSelected: (stmxSelected: boolean) => void
}

const Switcher = ({ stmxSelected, setStmxSelected }: SwitcherProps) => {
  return (
    <Fragment>
      <ToggleWrapper>
        <ToggleCurrencyItem selected={stmxSelected} onClick={() => setStmxSelected(true)}>
          <RowContainer>
            <ToggleCurrency selected={stmxSelected}>STMX V2</ToggleCurrency>
          </RowContainer>
        </ToggleCurrencyItem>

        <ToggleCurrencyItem selected={!stmxSelected} onClick={() => setStmxSelected(false)}>
          <RowContainer>
            <ToggleCurrency selected={!stmxSelected}>ATH</ToggleCurrency>
          </RowContainer>
        </ToggleCurrencyItem>
      </ToggleWrapper>
    </Fragment>
  )
}

export default Switcher
