import React, { useEffect, useState, Fragment } from 'react'
import { Button, Loader, Modal, Checkbox } from 'rsuite'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components'
import { get, cloneDeep, compact } from 'lodash'

import api from 'util/api'
import { accountState, web3State, termsOfUseState } from 'util/state'
import { getWeb3Adapter, personalSign } from 'util/web3'

import ProgressBox from './ProgressBox'
import ProgressContainer from './ProgressContainer'
import ProgressTick from './ProgressTick'

import ThemedModal from '../ThemedModal'
import MetaMaskImage from '../images/img-metamask.png'

interface Step3Props {
  isDone: boolean
  previousDone: boolean
  isMobileBrowser: boolean
  accountId?: string
  isLoading?: boolean
}

enum VerifyingStatus {
  NONE = 1,
  WALLET_REQUEST = 2,
  VERIFICATION_REQUEST = 3,
  VERIFICATION_VALIDATION = 4,
  SUCCESS = 5
}

const ConnectHeader = styled.div`
  color: rgb(43, 41, 105);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  border-bottom: 1px solid rgb(216, 216, 216);
  padding-bottom: 10px;
  margin-bottom: 10px;
`

const ConnectText = styled.div`
  color: rgb(38, 38, 38);
  font-family: Adelle;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: 20px;
`

const ConnectFirst = styled.div`
  &:before {
    content: '1';
    border: 2px solid rgb(116, 111, 127);
    border-radius: 50%;
    display: block;
    width: 31px;
    height: 31px;
    line-height: 28px;
    text-align: center;
    position: absolute;
    top: -8px;
    left: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
  }

  padding-left: 50px;
  position: relative;
  margin-bottom: 30px;
  margin-top: 30px;
`

const ConnectMobile = styled(ConnectFirst)`
  &:before {
    display: none;
  }

  padding-left: 0;
  margin-bottom: 0;
`

const ConnectSecond = styled.div`
  &:before {
    content: '2';
    border: 2px solid rgb(116, 111, 127);
    border-radius: 50%;
    display: block;
    width: 31px;
    height: 31px;
    line-height: 28px;
    text-align: center;
    position: absolute;
    top: -8px;
    left: 0;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: bold;
  }

  padding-left: 50px;
  position: relative;
`

const ConnectSectionHeader = styled.div`
  color: rgb(38, 38, 38);
  font-family: Adelle;
  font-size: 16px;
  font-weight: 600;
  height: 16px;
  letter-spacing: 0px;
  line-height: 16px;
`

const ConnectSectionLink = styled.a.attrs({
  href: 'https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn',
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  color: rgb(253, 46, 83);
  font-family: Adelle;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 16px;
  text-decoration: underline;
  margin-top: 10px;
  display: inline-block;
`

const ConnectSectionText = styled.div`
  color: rgb(86, 96, 105);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-top: 10px;
`

const MetaMaskIcon = styled.img.attrs({ src: MetaMaskImage })`
  height: 17px;
  margin-top: -4px;
`

const VerifyButton = styled(Button)`
  background: rgb(250, 46, 83);
  border-radius: 30px;
  height: 40px;
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  text-align: center;
  padding: 0 25px;
  margin-top: 15px;
  margin-left: 51px;
`

const ModalHeader = styled.div`
  &:before {
    content: ' ';
    background-color: #dfe1e2;
    display: inline-block;
    height: 1px;
    position: absolute;
    right: 80px;
    bottom: 0;
    left: 100px;
  }

  color: rgb(44, 37, 60);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 29px;
  text-align: center;
  margin-top: 30px;
  position: relative;
  box-sizing: content-box;
`

const StakingModalWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 30px;
`

const ModalText = styled.div`
  color: rgb(44, 37, 60);
  font-family: Adelle;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 24px;
  text-align: center;
  margin: 0 20px 10px 20px;
`

const ModalError = styled(ModalText)`
  color: red;
  font-weight: bold;
`

const VerificationLoaderWrapper = styled.div`
  height: 50px;
`

const VerificationLoader = styled(Loader)`
  margin-right: 10px;
`

const DesktopRowContainerSpaceBetween = styled.div`
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-left: -3px;
  margin-top: 30px;
`

const ConsentText = styled.div`
  color: rgb(86, 96, 105);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-left: 17px;
`

interface VerificationModalProps {
  setVerifying: React.Dispatch<React.SetStateAction<VerifyingStatus>>
  verifying: VerifyingStatus
  verificationError: string
}

const VerificationModal = ({ setVerifying, verifying, verificationError }: VerificationModalProps) => {
  let header = 'Connect a Member Wallet'
  let text = ''

  if (verifying === VerifyingStatus.VERIFICATION_REQUEST) {
    text = 'Sending Signature request...'
  } else if (verifying === VerifyingStatus.WALLET_REQUEST) {
    text = 'Waiting for your Signature...'
  } else if (verifying === VerifyingStatus.VERIFICATION_VALIDATION) {
    text = 'Validating the Signature...'
  }

  return (
    <ThemedModal overflow={false} show={verifying !== VerifyingStatus.NONE}>
      <Modal.Header onHide={() => setVerifying(VerifyingStatus.NONE)}>
        <ModalHeader>{verificationError ? `That didn't work` : header}</ModalHeader>
      </Modal.Header>
      <Modal.Body>
        <StakingModalWrapper>
          {verificationError ? (
            <Fragment>
              {verificationError !== 'Please connect your wallet and try again.' && (
                <ModalText>
                  Please return to StormX.
                  <br />
                  If this problem continues please get in touch with our support.
                </ModalText>
              )}
              <ModalError>{verificationError}</ModalError>
            </Fragment>
          ) : (
            <ModalText>
              <VerificationLoaderWrapper>
                <VerificationLoader size='md' />
              </VerificationLoaderWrapper>
              {text}
            </ModalText>
          )}
        </StakingModalWrapper>
      </Modal.Body>
    </ThemedModal>
  )
}

const DesktopConnect = ({
  loading,
  consent,
  consented,
  verifyWallet,
  checkboxChecked,
  setCheckboxChecked
}: {
  loading: boolean
  consented: boolean
  consent: () => void
  verifyWallet: () => void
  checkboxChecked: boolean
  setCheckboxChecked: (arg0: boolean) => void
}) => (
  <Fragment>
    <ConnectHeader>Connect with MetaMask</ConnectHeader>
    <ConnectText>Follow the steps below to connect your StormX account to MetaMask</ConnectText>

    <ConnectFirst>
      <ConnectSectionHeader>
        Don't have <MetaMaskIcon /> MetaMask installed?
      </ConnectSectionHeader>
      <ConnectSectionLink>Click here to download</ConnectSectionLink>
      <ConnectSectionText>Already installed and signed in? Skip to step 2.</ConnectSectionText>
    </ConnectFirst>

    <ConnectSecond>
      <ConnectSectionHeader>Open and verify</ConnectSectionHeader>
      <ConnectSectionText>
        Click Verify Wallet below to open up MetaMask and verify your wallet with StormX.
      </ConnectSectionText>
    </ConnectSecond>

    {!consented && (
      <DesktopRowContainerSpaceBetween>
        <Checkbox
          checked={checkboxChecked}
          onChange={(value, checked) => {
            setCheckboxChecked(checked)
          }}
        />
        <ConsentText>
          By checking this box, you agree to{' '}
          <a href='https://stormx.io/terms-of-use' rel='noopener noreferrer' target='_blank'>
            StormX's Terms of Use
          </a>{' '}
          and acknowledge that STMX and ATH tokens are used for memberships and governance respectively with no promise
          of financial value.
        </ConsentText>
      </DesktopRowContainerSpaceBetween>
    )}

    <VerifyButton
      appearance='primary'
      loading={loading}
      disabled={!checkboxChecked && !consented}
      onClick={() => {
        if (!consented && checkboxChecked) {
          if (!consented) {
            consent()
          }

          verifyWallet()
        } else if (consented) {
          verifyWallet()
        }
      }}
    >
      Verify Wallet
    </VerifyButton>
  </Fragment>
)

const MobileConnect = ({
  loading,
  consent,
  consented,
  verifyWallet,
  checkboxChecked,
  setCheckboxChecked
}: {
  loading: boolean
  consented: boolean
  consent: () => void
  verifyWallet: () => void
  checkboxChecked: boolean
  setCheckboxChecked: (arg0: boolean) => void
}) => (
  <ConnectMobile>
    <ConnectSectionHeader>Open and verify</ConnectSectionHeader>
    <ConnectSectionText>Click Verify Wallet below to open up your wallet and verify with StormX.</ConnectSectionText>

    {!consented && (
      <DesktopRowContainerSpaceBetween>
        <Checkbox
          checked={checkboxChecked}
          onChange={(value, checked) => {
            setCheckboxChecked(checked)
          }}
        />
        <ConsentText>
          By checking this box, you agree to{' '}
          <a href='https://stormx.io/terms-of-use' rel='noopener noreferrer' target='_blank'>
            StormX's Terms of Use
          </a>{' '}
          and acknowledge that STMX and ATH tokens are used for memberships and governance respectively with no promise
          of financial value.
        </ConsentText>
      </DesktopRowContainerSpaceBetween>
    )}

    <VerifyButton
      appearance='primary'
      loading={loading}
      disabled={!checkboxChecked && !consented}
      onClick={() => {
        if (!consented && checkboxChecked) {
          if (!consented) {
            consent()
          }

          verifyWallet()
        } else if (consented) {
          verifyWallet()
        }
      }}
    >
      Verify Wallet
    </VerifyButton>
  </ConnectMobile>
)

const Step2 = (props: Step3Props) => {
  getWeb3Adapter() // need this to make sure web3 adapter is loaded

  const [loading, setLoading] = useState(false)
  const [verificationSession, setVerificationSession] = useState({ sessionId: '', timestamp: 0 })
  const [verifying, setVerifying] = useState(VerifyingStatus.NONE)
  const [verificationError, setVerificationError] = useState('')
  const [checkboxChecked, setCheckboxChecked] = useState(false)

  const [authAccount, setAuthAccount] = useRecoilState(accountState)
  const { wallet } = useRecoilValue(web3State)

  const termsOfUseVersion = useRecoilValue(termsOfUseState)
  const decimal = Number.isInteger(termsOfUseVersion) ? '.0' : ''
  const touVersion = 'touV' + termsOfUseVersion + decimal
  const consented = get(authAccount.account?.flags, `${touVersion}`, false)

  const completed = props.isDone || verifying === VerifyingStatus.SUCCESS

  const verifyWallet = async () => {
    try {
      setVerificationError('')
      setVerifying(VerifyingStatus.WALLET_REQUEST)
      if (!wallet) {
        throw new Error('Please connect your wallet and try again.')
      }
      const signature = await personalSign(verificationSession.sessionId)

      setVerifying(VerifyingStatus.VERIFICATION_VALIDATION)
      try {
        const { data: postData } = await api.post('/erc-20/verify', {
          account: wallet,
          sessionId: verificationSession.sessionId,
          signature
        })
        if (postData.success) {
          setVerifying(VerifyingStatus.SUCCESS)
          const authAccountClone = cloneDeep(authAccount)
          if (authAccountClone.account) {
            if (!consented) {
              authAccountClone.account.flags = {
                [touVersion]: true
              }
            }

            authAccountClone.account.stormxRewards = {
              balance: postData.payload.balance,
              createdAt: new Date().toISOString(),
              level: postData.payload.level,
              wallet: postData.payload.wallet
            }
            setAuthAccount(authAccountClone)
          }
        }
      } catch (ex: any) {
        if (ex.response?.data?.code === 2092) {
          setVerificationError(`Wallet ${wallet} is already used by another account`)
        } else {
          throw ex
        }
      }
    } catch (ex: any) {
      if (ex.message.indexOf('MetaMask') === 0) {
        setVerificationError(ex.message)
      } else {
        setVerificationError(ex.message)
      }
    }
  }

  const consent = async () => {
    const operations = [
      {
        data: {
          [touVersion]: true
        },
        op: 'add',
        path: '/flags'
      }
    ]

    try {
      await api.patch('/account', {
        input: compact(operations)
      })
    } catch (error) {}
  }

  useEffect(() => {
    const getSessionId = async () => {
      setLoading(true)
      const { data: getData } = await api.get('/erc-20/session')
      const sessionId: string = getData.payload.verificationSessionId
      setVerificationSession({
        sessionId,
        timestamp: Date.now()
      })
      setLoading(false)
    }

    const shouldRefresh = () =>
      !completed &&
      !loading &&
      props.previousDone &&
      (verificationSession.timestamp === 0 || Date.now() - verificationSession.timestamp > 1500000)

    if (shouldRefresh()) {
      getSessionId()
    }

    const checkSessionId = setInterval(() => (shouldRefresh() ? getSessionId() : null), 1000)

    return () => clearInterval(checkSessionId)
  }, [completed, loading, props.previousDone, verificationSession.timestamp])

  return (
    <ProgressContainer>
      <ProgressTick
        completed={completed}
        isLoading={loading || (verifying !== VerifyingStatus.NONE && verifying !== VerifyingStatus.SUCCESS)}
        text='2'
      />
      <ProgressBox completed={completed} previousDone={props.previousDone} text='Connect a Member Wallet'>
        {props.isMobileBrowser ? (
          <MobileConnect
            loading={loading}
            consent={consent}
            consented={consented}
            verifyWallet={verifyWallet}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
        ) : (
          <DesktopConnect
            loading={loading}
            consent={consent}
            consented={consented}
            verifyWallet={verifyWallet}
            checkboxChecked={checkboxChecked}
            setCheckboxChecked={setCheckboxChecked}
          />
        )}

        <VerificationModal setVerifying={setVerifying} verifying={verifying} verificationError={verificationError} />
      </ProgressBox>
    </ProgressContainer>
  )
}

export default Step2
