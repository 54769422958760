import React, { Fragment } from 'react'
import { get } from 'lodash'
import { responsiveWidthM } from 'util/const'
import { useMediaQuery } from 'react-responsive'
import { StakingBalance } from '../earnings/index'
import { TOKENS, ContractEvents, WEB3_LOCK_TYPE } from 'util/web3'
import {
  Text,
  Button,
  Container,
  RowContainer,
  TopContainer,
  IconContainer,
  BottomContainer,
  CooldownReadyIcon,
  CooldownWaitingIcon
} from './components/index'

interface CooldownProps {
  timer: number
  cooldownAmount: string
  timerFormatted: string
  balance: StakingBalance
  stakeToken: (
    lockType: WEB3_LOCK_TYPE,
    amount: string,
    token: string,
    fakeUnstake: boolean,
    events?: ContractEvents
  ) => any
}

const Cooldown = ({ timer, balance, stakeToken, cooldownAmount, timerFormatted }: CooldownProps) => {
  const tokenBalance = get(balance, 'STMX_V2')
  const isSmallScreen = useMediaQuery({
    query: `(max-width: ${responsiveWidthM}px)`
  })

  return (
    <Fragment>
      {timer > 0 && tokenBalance.loaded && (
        <Container>
          <TopContainer unstakeNow={!timerFormatted}>
            <IconContainer>{timerFormatted ? <CooldownWaitingIcon /> : <CooldownReadyIcon />}</IconContainer>
            <RowContainer column center>
              <Text tokens-in-cooldown-message>{timerFormatted ? 'Tokens in cooldown:' : 'Ready to unstake:'}</Text>
              <Text tokens-in-cooldown>{cooldownAmount} STMX</Text>
            </RowContainer>
            <Button
              unstakeNow={!timerFormatted}
              onClick={() => {
                if (!timerFormatted) {
                  stakeToken(WEB3_LOCK_TYPE.UNLOCK, cooldownAmount, TOKENS.STMX_V2, false)
                }
              }}
            >
              <Text availability unstakeNow={!timerFormatted}>
                {timerFormatted ? `Available in ${timerFormatted}` : 'Unstake with no penalty'}
              </Text>
            </Button>
          </TopContainer>
          <BottomContainer>
            <RowContainer column center={isSmallScreen}>
              <Text mind-changed>Changed your mind?</Text>
              <Text mind-changed-explanation>
                You can cancel your cooldown to start earning rewards on these tokens again.
              </Text>
            </RowContainer>

            <Text
              cancel-cooldown
              onClick={() => {
                stakeToken(WEB3_LOCK_TYPE.UNLOCK, '0', TOKENS.STMX_V2, true)
              }}
            >
              Cancel and restake
            </Text>
          </BottomContainer>
        </Container>
      )}
    </Fragment>
  )
}

export default Cooldown
