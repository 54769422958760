import React from 'react'
import BigNumber from 'bignumber.js'
import styled from 'styled-components'
import { get } from 'lodash'
import { StakingBalance } from './earnings/index'
import { Button, FormControl, InputNumber } from 'rsuite'

export enum AmountControlType {
  LOCK = 'lock',
  UNLOCK = 'unlock'
}

interface AmountControlProps {
  balance: StakingBalance
  loading: boolean
  setAmount: React.Dispatch<React.SetStateAction<string>>
  amount: string
  type: AmountControlType
  noIcon?: boolean
  token: string
}

const Wrapper = styled.div`
  position: relative;
`

const AmountInput = styled(FormControl)`
  & > input {
    background-size: 28px 28px;
    font-size: 16px;
  }

  & > .rs-input-number-btn-group-vertical {
    display: none;
  }

  &.no-icon > input {
    background: none;
    padding-left: 20px;
  }

  width: 100% !important;
  border-radius: 8px;
  border: 1px solid rgb(250, 46, 83);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  height: 60px;
  color: rgb(44, 37, 60);
  font-family: Adelle;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  padding-right: 100px;
`

const MaxButton = styled(Button)`
  position: absolute;
  top: 3px;
  right: 2px;
  width: 100px;
  z-index: 10;
  padding: 15px !important;
  background: rgb(255, 255, 255);
  border: none;
  color: rgb(250, 46, 83);
  font-family: SFMono;
  font-size: 17px;
  font-weight: bold;
  letter-spacing: 0px;
`

const AmountController = ({ balance, loading, noIcon, setAmount, amount, type, token }: AmountControlProps) => {
  const tokenBalance = get(balance, `${token.replace(' ', '_')}`)
  const max = BigNumber(type === 'lock' ? tokenBalance.unlocked : tokenBalance.locked).toString()
  const typedBalance = BigNumber(tokenBalance[type === 'lock' ? 'unlocked' : 'locked']).toString()

  return (
    <Wrapper>
      <AmountInput
        accepter={InputNumber}
        className={noIcon ? 'no-icon' : ''}
        disabled={loading}
        max={BigNumber(max).toNumber()}
        min={0}
        name='stmx'
        onChange={(amount: number) => {
          if (!Number.isInteger(amount)) {
            amount = BigNumber(amount.toString()).toNumber()
          }

          if (Number.isNaN(amount)) {
            amount = 0
          }

          if (BigNumber(amount).isGreaterThan(typedBalance)) {
            amount = BigNumber(typedBalance).toNumber()
          }

          if (BigNumber(amount).isGreaterThan(max)) {
            setAmount(max)
          } else {
            setAmount(BigNumber(amount).toString())
          }
        }}
        step={Math.pow(10, typedBalance.toString().length - 1)}
        value={amount}
      />
      <MaxButton appearance='ghost' disabled={loading || !+typedBalance} onClick={() => setAmount(max)}>
        Max
      </MaxButton>
    </Wrapper>
  )
}

export default AmountController
