import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Loader } from 'rsuite'
import BigNumber from 'bignumber.js'
import { useRecoilState, useSetRecoilState } from 'recoil'

import api from 'util/api'
import { withModifiers } from 'util/styles'
import { responsiveWidthS, responsiveWidthM } from 'util/const'
import { ratesState, termsOfUseState } from 'util/state'

import BrowserNotSupported from './BrowserNotSupported'
import WalletConnectButton from './WalletConnectButton'

import QuantstampLogoImage from '../images/logo_quantstamp.png'
import TopCenterImage from '../images/logo-with-name-white.png'
import TopRightImage from '../images/masthead-angle-top-right.png'
import BottomLeftImage from '../images/angle-bottom-left.png'
import TriangleImage from '../images/triangle.svg'

interface WelcomeProps {
  isBrowserSupported: boolean
  isLoading: boolean
  isMobileBrowser: boolean
  setLetsGo: React.Dispatch<React.SetStateAction<boolean>>
  showJustTrustWalletWC: boolean
  walletBrowser: boolean
}

interface TextProps {
  line?: boolean
  terms?: boolean
  underline?: boolean
}

const Background = styled.div`
  @media only screen and (max-width: ${responsiveWidthS}px) {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -3;
  background: #2b2969;
`

const TopRight = styled.img.attrs({ src: TopRightImage })`
  @media only screen and (max-width: ${responsiveWidthS}px) {
    display: none;
  }

  height: 48px;
  width: 139px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
`

const TopCenter = styled.img.attrs({ src: TopCenterImage })`
  width: 139px;
  height: 48px;
  margin: 0 auto;
  display: flex;
  margin-top: 25px;
`

const BottomLeft = styled.img.attrs({ src: BottomLeftImage })`
  @media only screen and (max-width: ${BottomLeftImage}px) {
    display: none;
  }

  height: 113px;
  width: 434px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
`

const BottomCenter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  margin-bottom: 25px;
  flex-direction: row;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Inner = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    width: 460px;
  }

  @media only screen and (max-width: ${responsiveWidthS}px) {
    width: auto;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
  }

  background: rgb(255, 255, 255);
  border-radius: 32px;
  box-shadow:
    0px 1px 18px 0px rgba(0, 0, 0, 0.05),
    0px 4px 41px 0px rgba(0, 0, 0, 0.03);
  width: 555px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding-top: 40px;
`

const Content = styled.div`
  visibility: ${({ isHidden }: { isHidden: boolean }) => (isHidden ? 'hidden' : 'visible')};
`

const Title = styled.div`
  color: rgb(44, 37, 60);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  text-align: center;
  width: 350px;
  margin: 0 auto 24px auto;
`

const TextContainer = styled.div`
  width: 100%;
  justify-content: center;
  margin: 0 auto;
  display: flex;
`

const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('terms')`
    color: rgb(255, 255, 255);
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
    text-align: center;
    line-height: 16px;
  `};

  ${withModifiers('line')`
    margin-left: 3px;
    margin-right: 3px;
  `};

  ${withModifiers('underline')`
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  `};
`

const Point = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    margin-right: 10px;
    margin-left: 30px;
  }

  &:before {
    background: transparent url(${TriangleImage}) 0 0 no-repeat;
    content: ' ';
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 5px;
    left: 0;
  }

  text-align: left;
  color: rgb(44, 37, 60);
  font-family: Adelle;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  margin: 5px 70px 5px 90px;
  padding-left: 20px;
  position: relative;
`

const QuantstampAnchor = styled.a.attrs({
  href: 'https://certificate.quantstamp.com/full/stormx-token',
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  &:active,
  &:focus,
  &:hover {
    color: rgb(44, 37, 60);
    text-decoration: none;
  }

  color: rgb(44, 37, 60);
  text-decoration: none;
`

const QuantstampImage = styled.img.attrs({ src: QuantstampLogoImage })`
  width: 80px;
  height: 15px;
  margin-left: 5px;
`

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 40px;

  @media only screen and (max-width: ${responsiveWidthS}px) {
    padding-bottom: 20px;
  }
`

const ButtonLetsGo = styled(Button)`
  background: rgb(250, 46, 83);
  border-radius: 22px;
  height: 44px;
  width: 230px;
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  text-align: center;
`

const RateLoader = styled(Loader)`
  margin-right: 17px;
  margin-left: 17px;
`

const Welcome = ({
  isBrowserSupported,
  isLoading,
  isMobileBrowser,
  setLetsGo,
  showJustTrustWalletWC,
  walletBrowser
}: WelcomeProps) => {
  const [accountLoaded, setAccountLoaded] = useState(false)
  const [rates, setRates] = useRecoilState(ratesState)
  const setTermsOfUseVersion = useSetRecoilState(termsOfUseState)

  const loading = !accountLoaded && isLoading

  if (!accountLoaded && !isLoading) {
    setAccountLoaded(true)
  }

  useEffect(() => {
    const getConfig = async () => {
      try {
        const { data } = await api.get('/v2/config')
        if (data.payload) {
          const { VERSION_TOU, STAKING_RATE } = data.payload
          setTermsOfUseVersion(VERSION_TOU)
          setRates({
            loading: false,
            ATH: new BigNumber(STAKING_RATE.ATH).multipliedBy(100).toNumber(),
            STMX: new BigNumber(STAKING_RATE.STMX).multipliedBy(100).toNumber(),
            STMX_V2: new BigNumber(STAKING_RATE.STMX_V2).multipliedBy(100).toNumber()
          })
        } else {
          setTermsOfUseVersion(2)
        }
      } catch (error) {
        setTermsOfUseVersion(2)
      }
    }

    getConfig()
  }, [setRates, setTermsOfUseVersion])

  return (
    <Background>
      <TopCenter />
      <TopRight />
      <BottomLeft />

      <BottomCenter>
        <TextContainer>
          <Text terms>© Copyright {new Date().getFullYear()} StormX Singapore PTE LTD.</Text>
        </TextContainer>

        <TextContainer>
          <Text terms underline onClick={() => window.open('https://stormx.io/terms-of-use')}>
            Terms of use
          </Text>
          <Text terms line>
            |
          </Text>
          <Text terms underline onClick={() => window.open('https://stormx.io/privacy-policy')}>
            Privacy policy
          </Text>
        </TextContainer>
      </BottomCenter>

      <Inner>
        {loading && <Loader center size='lg' />}

        <Content isHidden={loading || (!isBrowserSupported && !isMobileBrowser)}>
          <Title>
            Earn STMX or ATH tokens
            {rates.STMX_V2 > 0 && (
              <>
                and get an additional{' '}
                {rates.loading ? <RateLoader /> : rates.STMX_V2 >= 0 ? `${rates.STMX_V2}%` : '...'} to{' '}
                {rates.loading ? <RateLoader /> : rates.ATH >= 0 ? `${rates.ATH}%` : '...'} p.a.
              </>
            )}
          </Title>
          <Point>Earn STMX/ATH in the app by shopping at your favorite stores.</Point>
          <Point>Unstake at anytime</Point>
          <Point>Staking bonus automatically sent to your StormX account weekly</Point>
          <Point>
            <QuantstampAnchor>
              Smart contract audited by <QuantstampImage />
            </QuantstampAnchor>
          </Point>
          <ButtonsWrapper>
            {!isMobileBrowser && (
              <ButtonLetsGo
                appearance='primary'
                onClick={() => {
                  setLetsGo(true)
                }}
              >
                Let's go!
              </ButtonLetsGo>
            )}
            {!walletBrowser && (
              <WalletConnectButton
                isMobileBrowser={isMobileBrowser}
                setLetsGo={setLetsGo}
                showJustTrustWalletWC={showJustTrustWalletWC}
              />
            )}
          </ButtonsWrapper>
        </Content>

        {!isBrowserSupported && !isMobileBrowser && !loading && <BrowserNotSupported />}
      </Inner>
    </Background>
  )
}

export default Welcome
