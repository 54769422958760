import styled from 'styled-components'

import { responsiveWidthS, responsiveWidthM } from 'util/const'

const Container = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    padding-right: 10px;
  }

  @media only screen and (max-width: ${responsiveWidthS}px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  margin: 40px auto;
  position: relative;
  padding-left: 65px;
  width: 100%;
`

const ProgressContainer = (props: any) => <Container {...props} />

export default ProgressContainer
