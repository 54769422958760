import styled from 'styled-components'
import { withModifiers } from 'util/styles'

interface TextProps {
  unlocked?: boolean
}

export const ModalHeader = styled.div`
  color: rgb(29, 29, 29);
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 800;
  text-align: center;
  line-height: 34px;
  margin-top: 25px;
  margin-bottom: 9px;
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('unlocked')`
    color: rgb(29, 29, 29);
    font-size: 15px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
    width: 100%;
  `};
`

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 34px;
`
