import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { IWalletConnectProviderOptions } from '@walletconnect/types'
import BigNumber from 'bignumber.js'
import { EventEmitter } from 'events'
import { isMobile } from 'react-device-detect'
import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import { Contract } from 'web3-eth-contract'
import isFunction from 'lodash/isFunction'
import isUndefined from 'lodash/isUndefined'

import StormXSmartContractAbi from 'misc/stormXSmartContractAbi.json'
import StormXV2SmartContractAbi from 'misc/stormXV2SmartContractAbi.json'
import StormXV2StakingContractAbi from 'misc/stormXV2stakingContract.json'
import AthensSmartContractAbi from 'misc/athensSmartContractAbi.json'
import SwapSmartContractAbi from 'misc/swapContractAbi.json'
import {
  isStaging,
  INFURA_TOKEN,
  WALLETCONNECT_ID,
  STORMX_TOKEN_ADDRESS,
  STORMX_V2_STAKING_ADDRESS,
  STORMX_V2_TOKEN_ADDRESS,
  ATHENS_TOKEN_ADDRESS,
  SWAP_CONTRACT_ADDRESS
} from 'util/const'

declare global {
  interface Window {
    ethereum: any
    web3: any
  }
}

if (isMobile && window.ethereum && isFunction(window.ethereum.on)) {
  let account = ''
  let networkId = 0
  window.ethereum
    .on('accountsChanged', (accounts: string[]) => {
      if (account && account !== accounts[0]) {
        window.location.reload()
      }
      account = accounts[0]
    })
    .on('chainChanged', (chainId: number) => {
      if (networkId && networkId !== chainId) {
        window.location.reload()
      }
      networkId = chainId
    })
}

export interface ContractEvents {
  [event: string]: (...args: any[]) => boolean | undefined | void
}

export enum WEB3_LOCK_TYPE {
  LOCK = 1,
  UNLOCK = 2
}

export enum WEB3_NETWORK_NAMES {
  MAINNET = 'mainnet',
  ROPSTEN = 'ropsten',
  RINKEBY = 'rinkeby',
  GOERLI = 'goerli',
  KOVAN = 'kovan'
}

// use this order to display the cards on the main screen
export const TOKENS = {
  STMX_V2: 'STMX V2',
  STMX: 'STMX',
  ATH: 'ATH'
}

let cachedIsSupported: boolean
let cachedWeb3Adapter: Web3 | null

const attachContractEvents = (contractEE: EventEmitter, events: ContractEvents): EventEmitter => {
  const eventNames = Object.keys(events)
  eventNames.forEach((event) => {
    const listener = events[event]
    const callback = (...args: any[]) => {
      const stopListening = listener(...args)
      if (stopListening) {
        contractEE.off(event, callback)
      }
    }
    contractEE.on(event, callback)
  })
  return contractEE
}

const getStormXSmartContract = (): Contract => {
  const web3 = getWeb3Adapter()
  return new web3.eth.Contract(StormXSmartContractAbi as AbiItem[], STORMX_TOKEN_ADDRESS)
}

const getStormXV2SmartContract = (): Contract => {
  const web3 = getWeb3Adapter()
  return new web3.eth.Contract(StormXV2SmartContractAbi as AbiItem[], STORMX_V2_TOKEN_ADDRESS)
}

const getStormXV2StakingContract = (): Contract => {
  const web3 = getWeb3Adapter()
  return new web3.eth.Contract(StormXV2StakingContractAbi as AbiItem[], STORMX_V2_STAKING_ADDRESS)
}

const getAthensSmartContract = (): Contract => {
  const web3 = getWeb3Adapter()
  return new web3.eth.Contract(AthensSmartContractAbi as AbiItem[], ATHENS_TOKEN_ADDRESS)
}

const getSwapSmartContract = (): Contract => {
  const web3 = getWeb3Adapter()
  return new web3.eth.Contract(SwapSmartContractAbi as AbiItem[], SWAP_CONTRACT_ADDRESS)
}

const getWeb3Accounts = async (): Promise<string[]> => {
  const web3 = getWeb3Adapter()

  let getAccounts
  if (web3.currentProvider instanceof EthereumProvider) {
    getAccounts = web3.eth.getAccounts
  } else if (isFunction(web3.eth.getAccounts) && !window.ethereum) {
    getAccounts = web3.eth.getAccounts
  } else if (isFunction(web3.eth.requestAccounts)) {
    getAccounts = web3.eth.requestAccounts
  } else {
    getAccounts = () => Promise.reject('Website not supported')
  }

  try {
    return await getAccounts()
  } catch (ex) {
    return []
  }
}

export const getWeb3Adapter = (): Web3 => {
  if (cachedWeb3Adapter) {
    return cachedWeb3Adapter
  } else {
    const { ethereum, web3 } = window
    let adapter
    if (!isUndefined(ethereum)) {
      ethereum.autoRefreshOnNetworkChange = false
      adapter = new Web3(ethereum)
      try {
        ethereum.enable()
      } catch (ex) {}
    } else if (!isUndefined(web3) && web3.currentProvider) {
      adapter = new Web3(web3.currentProvider)
    } else {
      adapter = new Web3(Web3.givenProvider)
    }

    cachedWeb3Adapter = adapter

    return adapter
  }
}

export const getWeb3AdapterCached = (): Web3 | null => cachedWeb3Adapter

export const getCooldown = async () => {
  const [web3Address] = await getWeb3Accounts()

  const stakingContract = getStormXV2StakingContract()
  const timers = await stakingContract.methods.timers(web3Address).call()

  return timers
}

export const getCooldownAmount = async () => {
  const web3 = getWeb3Adapter()
  const [web3Address] = await getWeb3Accounts()

  const stakingContract = getStormXV2StakingContract()
  const amount = await stakingContract.methods.amounts(web3Address).call()
  const amountInWei = web3.utils.fromWei(amount, 'ether')

  return amountInWei
}

export const getPenalty = async () => {
  const stakingContract = getStormXV2StakingContract()
  const penalty = await stakingContract.methods.penalty().call()

  return penalty / 100
}

export const getAccountBalance = async (token: string) => {
  const web3 = getWeb3Adapter()
  const [web3Address] = await getWeb3Accounts()

  if (token === TOKENS.STMX_V2) {
    const stmxV2Contract = getStormXV2SmartContract()
    const stakingContract = getStormXV2StakingContract()

    const availableBalance = await stmxV2Contract.methods.balanceOf(web3Address).call()
    const lockedBalance = await stakingContract.methods.staked(web3Address).call()
    const totalBalance = new BigNumber(web3.utils.fromWei(availableBalance)).plus(lockedBalance).toString()
    const unlockedBalance = new BigNumber(totalBalance).minus(lockedBalance).toString()

    return {
      locked: web3.utils.fromWei(lockedBalance, 'ether'),
      unlocked: unlockedBalance
    }
  }

  const contract = token === TOKENS.ATH ? getAthensSmartContract() : getStormXSmartContract()

  const lockedBalance = await contract.methods.lockedBalanceOf(web3Address).call()
  const unlockedBalance = await contract.methods.unlockedBalanceOf(web3Address).call()

  return {
    locked: web3.utils.fromWei(lockedBalance, 'ether'),
    unlocked: web3.utils.fromWei(unlockedBalance, 'ether')
  }
}

export const getNetworkId = async (): Promise<number> => {
  const web3 = getWeb3Adapter()
  try {
    return await web3.eth.getChainId()
  } catch (ex) {
    return -1
  }
}

export const getNetworkName = (chainId: number): string => {
  switch (chainId) {
    case 1:
      return WEB3_NETWORK_NAMES.MAINNET
    case 3:
      return WEB3_NETWORK_NAMES.ROPSTEN
    case 4:
      return WEB3_NETWORK_NAMES.RINKEBY
    case 5:
      return WEB3_NETWORK_NAMES.GOERLI
    case 42:
      return WEB3_NETWORK_NAMES.KOVAN
    default:
      return ''
  }
}

export const getWalletAddress = async (): Promise<string> => {
  const [web3Address] = await getWeb3Accounts()
  return web3Address ? web3Address.toLowerCase() : web3Address
}

export const isConnected = async (waitUntilConnected = false, waitCount = 5): Promise<boolean> =>
  new Promise(async (resolve, reject) => {
    if (waitUntilConnected) {
      let count = 0
      const waitInterval = setInterval(async () => {
        count += 0.1
        if (count >= waitCount) {
          resolve(false)
        } else {
          const connected = await isConnected(false)
          if (connected) {
            clearInterval(waitInterval)
            resolve(true)
          }
        }
      }, 100)
    } else {
      getWeb3Adapter().eth.net.isListening((err, status) => (err ? reject(err) : resolve(status)))
    }
  })

export const isSupported = () => {
  if (typeof cachedIsSupported !== 'boolean') {
    cachedIsSupported = !!getWeb3Adapter().givenProvider
  }
  return cachedIsSupported
}

export const lockTokenShouldIncreaseAllowance = async (amount: string) => {
  const web3 = getWeb3Adapter()
  const [web3Address] = await getWeb3Accounts()
  const amountWei = web3.utils.toWei(amount, 'ether')
  const allowance = await getStormXV2SmartContract().methods.allowance(web3Address, STORMX_V2_STAKING_ADDRESS).call()

  const allowanceFromWei = web3.utils.fromWei(allowance, 'ether')
  const amountFromWei = web3.utils.fromWei(amountWei, 'ether')

  const shouldIncreaseAllowance = new BigNumber(allowanceFromWei).isLessThan(amountFromWei)

  const difference = BigNumber(amountWei).minus(allowance).toString()

  return { shouldIncreaseAllowance, difference }
}

export const lockTokenIncreaseAllowance = async (amount: string, events: ContractEvents) => {
  const web3 = getWeb3Adapter()
  const [web3Address] = await getWeb3Accounts()

  const amountWei = web3.utils.toWei(amount, 'ether')

  const contractEE: EventEmitter = getStormXV2SmartContract()
    .methods.increaseAllowance(STORMX_V2_STAKING_ADDRESS, amountWei)
    .send({
      from: web3Address,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null
    })

  return await attachContractEvents(contractEE, events)
}

export const lockStmxV2Token = async (
  lock: WEB3_LOCK_TYPE,
  amount: string,
  fakeUnstake: boolean,
  events: ContractEvents
): Promise<EventEmitter> => {
  const web3 = getWeb3Adapter()

  const contract = getStormXV2SmartContract()

  // https://web3js.readthedocs.io/en/v1.3.4/web3-eth-contract.html#transactionblocktimeout
  contract.transactionBlockTimeout = 100
  contract.transactionPollingTimeout = 1500

  const [web3Address] = await getWeb3Accounts()

  // https://web3js.readthedocs.io/en/v1.3.4/web3-eth-contract.html#transactionblocktimeout
  contract.transactionBlockTimeout = 100
  contract.transactionPollingTimeout = 1500

  const amountWei = web3.utils.toWei(amount, 'ether')
  const errorWording = lock === WEB3_LOCK_TYPE.LOCK ? 'unstaked' : 'staked'
  const stakingContract = getStormXV2StakingContract()

  const contractMethod = lock === WEB3_LOCK_TYPE.LOCK ? 'stake' : fakeUnstake ? 'setCooldownTimer' : 'unstake'

  const availableBalance = await contract.methods.balanceOf(web3Address).call()
  const lockedBalance = await stakingContract.methods.staked(web3Address).call()
  const totalBalance = new BigNumber(availableBalance).plus(lockedBalance).toString()
  const unlockedBalance = new BigNumber(totalBalance).minus(lockedBalance).toString()

  const balanceWei = lock === WEB3_LOCK_TYPE.LOCK ? unlockedBalance : lockedBalance

  if (new BigNumber(balanceWei).isLessThan(amountWei)) {
    throw new Error(`You don't have enought ${errorWording}`)
  }

  const contractEE: EventEmitter = stakingContract.methods[contractMethod](amountWei).send({
    from: web3Address,
    maxPriorityFeePerGas: null,
    maxFeePerGas: null
    //gas: gasLimit,
    //gasPrice,
  })

  return await attachContractEvents(contractEE, events)
}

export const lockToken = async (
  isStmx: boolean,
  lock: WEB3_LOCK_TYPE,
  amount: string,
  events: ContractEvents
): Promise<EventEmitter> => {
  const web3 = getWeb3Adapter()
  const contract = isStmx ? getStormXSmartContract() : getAthensSmartContract()
  const [web3Address] = await getWeb3Accounts()

  // https://web3js.readthedocs.io/en/v1.3.4/web3-eth-contract.html#transactionblocktimeout
  contract.transactionBlockTimeout = 100
  contract.transactionPollingTimeout = 1500

  const balanceMethod = lock === WEB3_LOCK_TYPE.LOCK ? 'unlockedBalanceOf' : 'lockedBalanceOf'
  const errorWording = lock === WEB3_LOCK_TYPE.LOCK ? 'unstaked' : 'staked'
  const contractMethod = lock === WEB3_LOCK_TYPE.LOCK ? 'lock' : 'unlock'

  const balanceWei: number = await contract.methods[balanceMethod](web3Address).call()
  const amountWei = web3.utils.toWei(amount, 'ether')

  if (new BigNumber(balanceWei).isLessThan(amountWei)) {
    throw new Error(`You don't have enought ${errorWording}`)
  }

  /*let gasFast
  try { // uses https://ethgasstation.info
    const rates = await api.get('/rates')
    gasFast = get(rates, 'data.payload.rates.gasFast')
  } catch (ex) {}
  const gasLimit = await contract.methods[contractMethod](amountWei).estimateGas({ from: web3Address })
  let gasPrice = ''
  if (gasFast) {
    const gasPriceBN = new BigNumber(gasFast).toFixed()
    gasPrice = web3.utils.toWei(gasPriceBN)
  }*/

  const contractEE: EventEmitter = contract.methods[contractMethod](amountWei).send({
    from: web3Address,
    maxPriorityFeePerGas: null,
    maxFeePerGas: null
    //gas: gasLimit,
    //gasPrice,
  })
  return await attachContractEvents(contractEE, events)
}

export const swapShouldIncreaseAllowance = async (amount: string) => {
  const web3 = getWeb3Adapter()
  const [web3Address] = await getWeb3Accounts()
  const allowance = await getStormXSmartContract().methods.allowance(web3Address, SWAP_CONTRACT_ADDRESS).call()
  const amountWei = web3.utils.toWei(amount.toString(), 'ether')
  const shouldIncreaseAllowance = new BigNumber(allowance).isLessThan(amountWei)
  const difference = BigNumber(amountWei).minus(allowance).toString()
  return { shouldIncreaseAllowance, difference, allowance }
}

export const swapIncreaseAllowance = async (amount: string, events: ContractEvents) => {
  const web3 = getWeb3Adapter()
  const [web3Address] = await getWeb3Accounts()
  const amountWei = web3.utils.toWei(amount.toString(), 'ether')
  const contractEE: EventEmitter = getStormXSmartContract()
    .methods.increaseAllowance(SWAP_CONTRACT_ADDRESS, amountWei)
    .send({
      from: web3Address,
      maxPriorityFeePerGas: null,
      maxFeePerGas: null
    })

  return await attachContractEvents(contractEE, events)
}

export const swap = async (amount: string, events: ContractEvents) => {
  const web3 = getWeb3Adapter()
  const [web3Address] = await getWeb3Accounts()
  const amountWei = web3.utils.toWei(amount.toString(), 'ether')

  const contractEE: EventEmitter = getSwapSmartContract().methods.convert(amountWei.toString()).send({
    from: web3Address,
    maxPriorityFeePerGas: null,
    maxFeePerGas: null
  })

  return await attachContractEvents(contractEE, events)
}

export const personalSign = async (message: string): Promise<string> => {
  const web3 = getWeb3Adapter()
  const [web3Address] = await getWeb3Accounts()

  const signedMessage = await web3.eth.personal.sign(message, web3Address, '')
  return signedMessage
}

export const enableWalletConnect = async (options?: IWalletConnectProviderOptions): Promise<string[]> => {
  // clear WalletConnect settings to force reconnect with wallet app
  window.localStorage.removeItem('walletconnect')

  // this is to hack rpc configuration object, because most wallet apps won't support networks other than mainnet
  // and we need to use goerli, because StormX smart contract is deployed on it
  let infuraMainnetEndpoint = WEB3_NETWORK_NAMES.MAINNET
  if (isStaging) {
    infuraMainnetEndpoint = WEB3_NETWORK_NAMES.GOERLI
  }

  // const walletConnectProvider = new WalletConnectProvider({
  //   rpc: {
  //     1: `https://${infuraMainnetEndpoint}.infura.io/v3/${INFURA_TOKEN}`,
  //     3: `https://${WEB3_NETWORK_NAMES.ROPSTEN}.infura.io/v3/${INFURA_TOKEN}`,
  //     4: `https://${WEB3_NETWORK_NAMES.RINKEBY}.infura.io/v3/${INFURA_TOKEN}`,
  //     5: `https://${WEB3_NETWORK_NAMES.GOERLI}.infura.io/v3/${INFURA_TOKEN}`,
  //     42: `https://${WEB3_NETWORK_NAMES.KOVAN}.infura.io/v3/${INFURA_TOKEN}`
  //   },
  //   qrcodeModalOptions: {
  //     mobileLinks: ['trust', 'rainbow', 'argent', 'metamask']
  //   },
  //   ...options
  // })
  const walletConnectProvider = await EthereumProvider.init({
    projectId: WALLETCONNECT_ID,
    chains: [1], //mainnet
    optionalChains: [5], //goerli
    showQrModal: true,
    rpcMap: {
      1: `https://${infuraMainnetEndpoint}.infura.io/v3/${INFURA_TOKEN}`,
      3: `https://${WEB3_NETWORK_NAMES.ROPSTEN}.infura.io/v3/${INFURA_TOKEN}`,
      4: `https://${WEB3_NETWORK_NAMES.RINKEBY}.infura.io/v3/${INFURA_TOKEN}`,
      5: `https://${WEB3_NETWORK_NAMES.GOERLI}.infura.io/v3/${INFURA_TOKEN}`,
      42: `https://${WEB3_NETWORK_NAMES.KOVAN}.infura.io/v3/${INFURA_TOKEN}`
    }
  })

  walletConnectProvider.on('disconnect', () => {
    cachedWeb3Adapter = null
    window.location.reload()
  })

  const wallets = await walletConnectProvider.enable()

  // @ts-ignore web3@1.3.0 and @walletconnect/web3-provider 1.3.1 TypeScript types are not fully compatible
  cachedWeb3Adapter = new Web3(walletConnectProvider)

  return wallets
}
