import styled from 'styled-components'
import { withModifiers } from 'util/styles'
import iconInfo from 'components/staking/images/icon-info.svg'
import rightArrow from 'components/staking/images/arrow-right-white.png'
import radioButton from 'components/staking/images/radio-button.png'
import radioButtonActive from 'components/staking/images/radio-button-active.png'

interface TextProps {
  info?: boolean
  goBack?: boolean
  locked?: boolean
  button?: boolean
  listItem?: boolean
  optionTitle?: boolean
  recommended?: boolean
  optionDescription?: boolean
}

interface OptionProps {
  selected?: boolean
  addMargin?: boolean
}

interface CenterContainerProps {
  goBack?: boolean
}

interface RowContainerProps {
  column?: boolean
  withSpaceBetween?: boolean
}

export const RightArrow = styled.img.attrs({
  src: rightArrow
})`
  width: 24px;
  height: 24px;
  margin-right: 18px;
`

export const RadioButton = styled.img.attrs({
  src: radioButton
})`
  height: 30px;
  width: 30px;
  margin-right: 17px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

export const RadioButtonActive = styled.img.attrs({
  src: radioButtonActive
})`
  height: 30px;
  width: 30px;
  margin-right: 17px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

export const EmptyDiv = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 18px;
`

export const CenterContainer = styled.div<CenterContainerProps>`
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 34px;

  ${withModifiers('goBack')`
    margin-bottom: 0px;
    margin-top: 18px;
  `};
`

export const ModalHeader = styled.div`
  color: rgb(29, 29, 29);
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 800;
  text-align: center;
  line-height: 34px;
  margin-top: 25px;
  margin-bottom: 9px;
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('locked')`
    color: rgb(29, 29, 29);
    font-size: 15px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
    width: 100%;
  `};

  ${withModifiers('optionTitle')`
    color: rgb(29, 29, 29);
    font-size: 15px;
    font-family: Montserrat;
    font-weight: bold;
    line-height: 22px;
  `};

  ${withModifiers('optionDescription')`
    color: rgb(29, 29, 29);
    font-size: 15px;
    font-family: Adelle;
    font-weight: normal;
    margin-top: 3px;

    ${withModifiers('listItem')`
      margin-left: -8px;
    `};
  `};

  ${withModifiers('recommended')`
    color: rgb(253, 46, 83);
    font-size: 11px;
    font-family: Montserrat;
    font-weight: bold;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    line-height: 14px;
    margin-top: -15px;
  `};

  ${withModifiers('button')`
    color: rgb(254, 255, 255);
    font-size: 14px;
    font-family: Montserrat;
    font-weight: bold;
    text-align: center;
    line-height: 18px;
    cursor: pointer;
  
    &:hover {
      opacity: 0.5;
    }
  `};

  ${withModifiers('goBack')`
    color: rgb(250, 46, 83);
    font-size: 14px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    text-decoration-line: underline;
    text-decoration-color: rgb(250, 46, 83);
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  `};

  ${withModifiers('info')`
    color: rgb(86, 96, 105);
    font-size: 11px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  `};
`

export const OptionContainer = styled.div<OptionProps>`
  width: 100%;
  background: rgb(234, 237, 239);
  border-radius: 18px;
  padding-left: 18px;
  padding-right: 18px;
  padding-top: 20px;
  padding-bottom: 20px;
  flex-direction: row;
  display: flex;
  border: 2px solid transparent;
  margin-top: 30px;

  ${withModifiers('selected')`
    background: rgb(255, 255, 255);
    border: 2px solid rgb(253, 46, 83);
  `};

  ${withModifiers('addMargin')`
    margin-top: 18px;
  `};
`

export const RowContainer = styled.div<RowContainerProps>`
  flex-direction: row;
  align-items: center;
  display: flex;

  ${withModifiers('withSpaceBetween')`
    justify-content: space-between;
    width: 100%;
  `};

  ${withModifiers('column')`
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    justify-content: center;
  `};
`

export const ListContainer = styled.div`
  margin-left: 10px;
`

export const ContinueButton = styled.button`
  width: 260px;
  height: 44px;
  background: rgb(253, 46, 83);
  border-radius: 22px;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 30px;

  &:hover {
    opacity: 0.5;
  }
`

export const IconInfo = styled.img.attrs({ src: iconInfo })`
  width: 24px;
  height: 24px;
  justify-content: center;
  display: flex;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 7px;
  margin-top: 35px;
`
