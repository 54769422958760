import styled from 'styled-components'
import { Loader } from 'rsuite'

import { responsiveWidthM } from 'util/const'

import CheckImage from '../images/check.svg'

interface ProgressTickProps {
  text: string
  completed?: boolean
  isLoading?: boolean
}

const TickBox = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    left: 10px;
  }

  background: rgb(250, 46, 83) 50% 50% no-repeat;
  background-size: 14px 12px;
  ${({ completed }: { completed?: boolean }) => (completed ? `background-image: url(${CheckImage});` : '')}
  border-radius: 8px;
  border: 1px solid rgba(56, 56, 56, 0.1);
  height: 40px;
  width: 40px;
  color: rgb(255, 255, 255);
  font-family: Adelle;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 40px;
  text-align: center;
  position: absolute;
  top: 15px;
  left: 0;
`

const TickContent = styled.div`
  margin-top: -1px;
  margin-left: -1px;
  height: 40px;
  width: 40px;
  position: relative;
`

const ProgressTick = ({ completed, isLoading, text }: ProgressTickProps) => (
  <TickBox completed={completed}>
    <TickContent>{isLoading ? <Loader center /> : completed ? '' : text}</TickContent>
  </TickBox>
)

export default ProgressTick
