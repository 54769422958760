import styled from 'styled-components'

import BrowserBraveImage from '../images/browser-brave.svg'
import BrowserChromeImage from '../images/browser-chrome.svg'
import BrowserEdgeImage from '../images/browser-edge.svg'
import BrowserOperaImage from '../images/browser-opera.svg'

const CHROME_URL = 'https://www.google.com/chrome/'
const EDGE_URL = 'https://www.microsoft.com/edge'
const OPERA_URL = 'https://www.opera.com/download'
const BRAVE_URL = 'https://brave.com/'

const Wrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: Montserrat;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  width: 80%;
`

const SupportedBrowsers = styled.div`
  margin: 20px 0;
`

const BrowserBrave = styled.img.attrs({ src: BrowserBraveImage })`
  height: 64px;
  width: 64px;
`

const BrowserChrome = styled.img.attrs({ src: BrowserChromeImage })`
  height: 64px;
  width: 64px;
`

const BrowserEdge = styled.img.attrs({ src: BrowserEdgeImage })`
  height: 64px;
  width: 64px;
`

const BrowserOpera = styled.img.attrs({ src: BrowserOperaImage })`
  height: 64px;
  width: 64px;
`

const BrowserBraveAnchor = styled.a.attrs({
  href: BRAVE_URL,
  rel: 'noopener noreferrer',
  target: '_blank'
})``

const BrowserChromeAnchor = styled.a.attrs({
  href: CHROME_URL,
  rel: 'noopener noreferrer',
  target: '_blank'
})``

const BrowserEdgeAnchor = styled.a.attrs({
  href: EDGE_URL,
  rel: 'noopener noreferrer',
  target: '_blank'
})``

const BrowserOperaAnchor = styled.a.attrs({
  href: OPERA_URL,
  rel: 'noopener noreferrer',
  target: '_blank'
})``

const BrowserIcon = styled.div`
  display: inline-block;
  margin: 15px 15px;
`

const BrowserSupport = () => {
  return (
    <Wrapper>
      Sorry{' '}
      <span role='img' aria-label='sad face'>
        😕
      </span>{' '}
      Your Browser is not supported.
      <br />
      Try any of the following instead.
      <SupportedBrowsers>
        <BrowserIcon>
          <BrowserChromeAnchor>
            <BrowserChrome />
          </BrowserChromeAnchor>
        </BrowserIcon>
        <BrowserIcon>
          <BrowserEdgeAnchor>
            <BrowserEdge />
          </BrowserEdgeAnchor>
        </BrowserIcon>
        <BrowserIcon>
          <BrowserOperaAnchor>
            <BrowserOpera />
          </BrowserOperaAnchor>
        </BrowserIcon>
        <BrowserIcon>
          <BrowserBraveAnchor>
            <BrowserBrave />
          </BrowserBraveAnchor>
        </BrowserIcon>
      </SupportedBrowsers>
    </Wrapper>
  )
}

export default BrowserSupport
