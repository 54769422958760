import { useEffect, useState, useCallback } from 'react'
import { useSetRecoilState } from 'recoil'
import { get, now } from 'lodash'

import api from 'util/api'
import { stakingState } from 'util/state'

const SummarySubscription = () => {
  const setStaking = useSetRecoilState(stakingState)
  const [firstLoad, setFirstLoad] = useState(false)

  const fetchStaking = useCallback(async () => {
    try {
      const startTime = now()
      const { data } = await api.get(`/v2/staking/summary`)

      const stmxv2Rate: number = get(data, 'STMX_V2.rate', -1)

      if (stmxv2Rate > -1) {
        const duration = now() - startTime
        const tsDifference = now() - data.timestamp - duration
        setStaking({
          notLoaded: false,
          summary: {
            ...data,
            tsDifference
          }
        })
      }
    } catch (error: any) {
      // error code 4003 means that rewards wallet was disconnected
      if (error?.response?.data?.code === 4003) {
        window.location.reload()
      } else {
        setStaking({
          notLoaded: true
        })
      }
    }
  }, [setStaking])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchStaking()
    }, 180000)
    if (!firstLoad) {
      setFirstLoad(true)
      fetchStaking()
    }

    return () => clearInterval(interval)
  }, [firstLoad, fetchStaking, setStaking])

  return null
}

export default SummarySubscription
