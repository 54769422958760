import { useState, Fragment } from 'react'
import Switcher from '../switcher'
import { get } from 'lodash'
import { Table } from 'rsuite'
import { useRecoilValue } from 'recoil'
import { stakingState } from 'util/state'
import { numberWithCommas } from 'util/utils'
import { responsiveWidthS } from 'util/const'
import { useMediaQuery } from 'react-responsive'
import {
  Header,
  Wrapper,
  ThemedTable,
  RowContainer,
  StakeSomeTokens,
  YourRankingHeader,
  YourRankingWrapper
} from './components/index'

interface LeaderboardProps {
  isMobileBrowser: boolean
}

const EMPTY_RANK = {
  balance: '--',
  nickname: '--',
  rank: '--',
  wallet: '--'
}

const Leaderboard = ({ isMobileBrowser }: LeaderboardProps) => {
  const [stmxSelected, setStmxSelected] = useState(true)
  const stakingstate = useRecoilValue(stakingState)
  const stakingAccount = {
    ...stakingstate,
    summary: get(stakingstate, `summary.${stmxSelected ? 'STMX_V2' : 'ATH'}`, undefined)
  }
  const isSmallScreen = useMediaQuery({
    query: `(max-width: ${responsiveWidthS}px)`
  })

  if (stakingAccount.notLoaded) {
    return <Fragment />
  } else {
    const currentLeaderboardRank = stakingAccount?.summary?.metrics?.stakingMetrics?.currentLeaderboardRank
    const leaderboard = stakingAccount?.summary?.metrics?.stakingMetrics?.leaderboard.map((item: any) => ({
      ...item,
      balance: item?.balance ? numberWithCommas(parseInt(item.balance.toString())) : 0
    }))

    if (leaderboard) {
      return (
        <Wrapper>
          <RowContainer withSpaceBetween={!isSmallScreen} column={isSmallScreen}>
            <Header isSmallScreen={isSmallScreen}>Leaderboard</Header>
            <Switcher stmxSelected={stmxSelected} setStmxSelected={setStmxSelected} />
          </RowContainer>

          <ThemedTable
            autoHeight={isMobileBrowser ? false : true}
            data={leaderboard}
            height={isMobileBrowser ? 350 : undefined}
            hover={false}
            rowClassName={(rowData: any) => {
              const classNames = [rowData && rowData.rank % 2 === 1 ? 'rs-table-row-odd' : 'rs-table-row-even']
              if (rowData && currentLeaderboardRank && rowData.nickname === currentLeaderboardRank?.nickname) {
                classNames.push('rs-table-row-highlighted')
              }
              return classNames.join(' ')
            }}
          >
            {/* @ts-ignore */}
            <Table.Column align='left' width={90}>
              <Table.HeaderCell
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: 600,
                  fontSize: 11,
                  marginLeft: '10px',
                  letterSpacing: 0.8
                }}
              >
                RANK
              </Table.HeaderCell>
              <Table.Cell dataKey='rank' style={{ marginLeft: '10px' }} />
            </Table.Column>

            {/* @ts-ignore */}
            <Table.Column align='left' flexGrow={1}>
              <Table.HeaderCell
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: 600,
                  fontSize: 11,
                  letterSpacing: 0.8
                }}
              >
                NICKNAME
              </Table.HeaderCell>
              <Table.Cell dataKey='nickname' />
            </Table.Column>

            {/* @ts-ignore */}
            <Table.Column align='left'>
              <Table.HeaderCell
                style={{
                  fontFamily: 'Montserrat',
                  fontWeight: 600,
                  fontSize: 11,
                  letterSpacing: 0.8
                }}
              >
                {stmxSelected ? 'STMX' : 'ATH'} TVL
              </Table.HeaderCell>
              <Table.Cell dataKey='balance' style={{ fontFamily: 'SFMono', fontWeight: 'bold' }} />
            </Table.Column>
          </ThemedTable>

          <YourRankingHeader>Your ranking</YourRankingHeader>
          <YourRankingWrapper>
            <ThemedTable
              autoHeight={true}
              data={
                currentLeaderboardRank
                  ? [
                      {
                        ...currentLeaderboardRank,
                        balance: numberWithCommas(
                          parseInt(currentLeaderboardRank.balance ? currentLeaderboardRank.balance.toString() : 0)
                        )
                      }
                    ]
                  : [EMPTY_RANK]
              }
              hover={false}
              showHeader={false}
            >
              {/* @ts-ignore */}
              <Table.Column align='left' width={90}>
                <Table.HeaderCell
                  style={{
                    fontFamily: 'Montserrat',
                    fontWeight: 600,
                    fontSize: 11,
                    marginLeft: '10px',
                    letterSpacing: 0.8
                  }}
                >
                  RANK
                </Table.HeaderCell>
                <Table.Cell dataKey='rank' style={{ marginLeft: '10px' }} />
              </Table.Column>
              {/* @ts-ignore */}
              <Table.Column align='left' flexGrow={1}>
                <Table.HeaderCell
                  style={{
                    fontFamily: 'Montserrat',
                    fontWeight: 600,
                    fontSize: 11,
                    letterSpacing: 0.8
                  }}
                >
                  Nickname
                </Table.HeaderCell>
                <Table.Cell dataKey='nickname' />
              </Table.Column>
              {/* @ts-ignore */}
              <Table.Column align='left'>
                <Table.HeaderCell
                  style={{
                    fontFamily: 'Montserrat',
                    fontWeight: 600,
                    fontSize: 11,
                    letterSpacing: 0.8
                  }}
                >
                  {stmxSelected ? 'STMX' : 'ATH'} TVL
                </Table.HeaderCell>
                <Table.Cell dataKey='balance' style={{ fontFamily: 'SFMono', fontWeight: 'bold' }} />
              </Table.Column>
            </ThemedTable>
          </YourRankingWrapper>
          {!currentLeaderboardRank && <StakeSomeTokens>Stake some tokens to join the leaderboard!</StakeSomeTokens>}
        </Wrapper>
      )
    } else {
      return <Fragment />
    }
  }
}

export default Leaderboard
