import localForage from 'localforage'

export interface AuthStore {
  devicename: string
  token: string
  udid: string
  'aff-id'?: string
  version?: string
}

const store = localForage.createInstance({
  description: 'StormX Web App,',
  name: 'stormx-web-app'
})

export default store
