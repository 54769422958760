import { browserName, isChrome, isIE, isSafari, isFirefox, isOpera, isEdge } from 'react-device-detect'

const isBrave = browserName === 'Brave'
const isBlink = (isChrome || isOpera) && !!window.CSS

export const detection = {
  isBlink,
  isChrome,
  isEdge,
  isEdgeChromium: isEdge,
  isFirefox,
  isIE,
  isOpera,
  isSafari,
  isBrave
}
