import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { API_URL, ENVIRONMENT, EXTENSION_URL, MOONPAY_API_KEY, STORMX_TOKEN_ADDRESS, isProduction } from 'util/const'

const Wrapper = styled.div`
  position: fixed;
  z-index: 5000;
  top: 0;
  left: 0;
  text-align: left;
  font-weigh: bold;
  font: sans-serif;
  color: #000;
  background: #fff;
  padding: 10px;
  opacity: 0.8;
`

const Item = styled.div``

const Environment = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.key === '~') {
        setShow(!show)
      }
    }

    window.addEventListener('keyup', listener)

    return () => window.removeEventListener('keyup', listener)
  }, [show])

  if (isProduction || !show) {
    return null
  }

  return (
    <Wrapper>
      <Item>
        API_URL: <b>{API_URL}</b>
      </Item>
      <Item>
        ENVIRONMENT: <b>{ENVIRONMENT}</b>
      </Item>
      <Item>
        EXTENSION_URL: <b>{EXTENSION_URL}</b>
      </Item>
      <Item>
        MOONPAY_API_KEY: <b>{MOONPAY_API_KEY}</b>
      </Item>
      <Item>
        STORMX_TOKEN_ADDRESS: <b>{STORMX_TOKEN_ADDRESS}</b>
      </Item>
    </Wrapper>
  )
}

export default Environment
