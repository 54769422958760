import React from 'react'
import ThemedModal from '../../ThemedModal'
import { Modal } from 'rsuite'
import { COOLDOWN_PERIOD } from 'util/const'
import { Text, EmptyDiv, Container, RightArrow, ModalHeader, CooldownImage, ContinueButton } from './components/index'

interface CooldownDialogProps {
  token: string
  timeFormatted: string
  cooldownAmount: string
  showCooldownDialog: boolean
  setTokenSelected: React.Dispatch<React.SetStateAction<string>>
  setShowUnstakeDialog: React.Dispatch<React.SetStateAction<boolean>>
  setShowCooldownDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const CooldownDialog = ({
  token,
  timeFormatted,
  cooldownAmount,
  setTokenSelected,
  showCooldownDialog,
  setShowUnstakeDialog,
  setShowCooldownDialog
}: CooldownDialogProps) => {
  return (
    <ThemedModal
      overflow={false}
      show={showCooldownDialog}
      onExit={() => {
        setShowCooldownDialog(false)
      }}
    >
      <Modal.Header onHide={() => setShowCooldownDialog(false)}>
        <CooldownImage />
        <ModalHeader>Before you continue...</ModalHeader>
      </Modal.Header>
      <Container>
        <Text description>
          <Text description>You already have </Text>
          <Text description bold>
            {cooldownAmount} STMX
          </Text>
          <Text description>
            {' '}
            in a cooldown{timeFormatted ? ` ending in ${timeFormatted}` : ''}. Unstaking any additional tokens will
            start a new {COOLDOWN_PERIOD} day cooldown.
          </Text>
        </Text>
      </Container>
      <Container>
        <Text description bold>
          You can always unstake immediately with a penalty.
        </Text>
      </Container>

      <ContinueButton
        onClick={() => {
          setTokenSelected(token)
          setShowUnstakeDialog(true)
          setShowCooldownDialog(false)
        }}
      >
        <EmptyDiv />
        <Text button>See my options</Text>
        <RightArrow />
      </ContinueButton>
    </ThemedModal>
  )
}

export default CooldownDialog
