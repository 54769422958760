import { Fragment, useState } from 'react'
import moment from 'moment'
import Switcher from '../switcher'
import BigNumber from 'bignumber.js'
import ThemedTooltip from '../../ThemedTooltip'
import { get } from 'lodash'
import { Whisper } from 'rsuite'
import { useRecoilValue } from 'recoil'
import { stakingState } from 'util/state'
import { responsiveWidthS } from 'util/const'
import { useMediaQuery } from 'react-responsive'
import { numberWithCommas, toUSD } from 'util/utils'
import {
  Text,
  Header,
  Wrapper,
  IconInfo,
  TimeButton,
  AmountInput,
  RowContainer,
  TypeButtonGroup
} from './components/index'

const MAX_STMX = 1000000000

const TYPES = [
  {
    label: '1 week',
    mobileLabel: '1 W',
    multiplier: 7 / (moment().isLeapYear() ? 366 : 365)
  },
  {
    label: '1 month',
    mobileLabel: '1 M',
    multiplier: 0.083333333
  },
  {
    label: '6 months',
    mobileLabel: '6 M',
    multiplier: 0.5
  },
  {
    label: '1 year',
    mobileLabel: '1 Y',
    multiplier: 1
  }
]

const Calculator = () => {
  const [amount, setAmount] = useState<number | undefined>(undefined)
  const [selectedType, setSelectedType] = useState(TYPES[3])
  const [stmxSelected, setStmxSelected] = useState(true)
  const stakingstate = useRecoilValue(stakingState)
  const stakingAccount = {
    ...stakingstate,
    summary: get(stakingstate, `summary.${stmxSelected ? 'STMX_V2' : 'ATH'}`, undefined)
  }
  const isSmallScreen = useMediaQuery({
    query: `(max-width: ${responsiveWidthS}px)`
  })

  if (stakingAccount.notLoaded) {
    return <Fragment />
  } else {
    const rate = stakingAccount?.summary?.rate || 0
    const calculated = new BigNumber(rate).multipliedBy(amount as number).multipliedBy(selectedType.multiplier)
    const tokenUsdRate = stakingAccount?.summary?.metrics?.stakingMetrics?.tokenUsdRate || 0

    const setStmx = (amount: number) => {
      if (amount > MAX_STMX) {
        setAmount(MAX_STMX)
      } else if (amount < 0) {
        setAmount(0)
      } else {
        setAmount(amount)
      }
    }

    return (
      <Wrapper>
        <RowContainer withSpaceBetween={!isSmallScreen} column={isSmallScreen}>
          <Header isSmallScreen={isSmallScreen}>
            Estimate Rewards
            <Whisper
              preventOverflow
              trigger='hover'
              speaker={
                <ThemedTooltip>
                  Rewards are estimated using the current price of {stmxSelected ? 'STMX' : 'ATH'} which fluctuates over
                  time.
                </ThemedTooltip>
              }
              placement='bottom'
            >
              <IconInfo />
            </Whisper>
          </Header>

          <Switcher stmxSelected={stmxSelected} setStmxSelected={setStmxSelected} />
        </RowContainer>

        <AmountInput
          max={MAX_STMX}
          onChange={setStmx}
          placeholder={`Staking amount (${stmxSelected ? 'STMX' : 'ATH'})`}
          postfix={
            <Text estimatedRewardAmount>
              ≈ ${!amount && amount !== 0 ? '0.00' : numberWithCommas(toUSD(amount, tokenUsdRate))} USD
            </Text>
          }
          value={amount}
        />

        <TypeButtonGroup justified>
          {TYPES.map((item) => (
            <TimeButton
              selected={selectedType.label === item.label}
              key={item.label}
              onClick={() => setSelectedType(item)}
            >
              <Text time timeSelected={selectedType.label === item.label}>
                {isSmallScreen ? item.mobileLabel : item.label}
              </Text>
            </TimeButton>
          ))}
        </TypeButtonGroup>

        <Text estimatedReward>Estimated Staking Rewards</Text>

        <RowContainer estimatedReward>
          <Text reward bold>
            {calculated.isNaN() ? 0 : numberWithCommas(calculated.toFixed(2))}
          </Text>
          <Text reward>&nbsp;{stmxSelected ? 'STMX' : 'ATH'}&nbsp;&nbsp;</Text>
          <Text reward bold>
            ≈ {calculated.isNaN() ? '0.00' : numberWithCommas(toUSD(calculated, tokenUsdRate))}
          </Text>
          <Text reward>&nbsp;USD</Text>
        </RowContainer>
      </Wrapper>
    )
  }
}

export default Calculator
