import styled from 'styled-components'

const HeaderModalHeader = styled.div`
  &:before {
    content: ' ';
    background-color: #dfe1e2;
    display: inline-block;
    height: 1px;
    position: absolute;
    right: 0px;
    bottom: 0;
    left: 20px;
  }

  color: rgb(44, 37, 60);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 15px;
  text-align: center;
  margin-top: 15px;
  position: relative;
  box-sizing: content-box;
  width: 300px;
  margin-left: auto;
  margin-right: auto;
`

export const HeaderModalHeaderBuyStmx = styled(HeaderModalHeader)`
  margin-top: 35px;
  padding-bottom: 35px;
`

export const BuyStmxWrapper = styled.div`
  margin-bottom: 50px;
`
