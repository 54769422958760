import React from 'react'
import styled from 'styled-components'
import iconInfo from '../images/icon-info.svg'
import { get } from 'lodash'
import { Form, FormGroup } from 'rsuite'
import { withModifiers } from 'util/styles'
import { COOLDOWN_PERIOD } from 'util/const'
import { StakingBalance } from './earnings/index'
import { ContractEvents, WEB3_LOCK_TYPE } from 'util/web3'
import AmountControl, { AmountControlType } from './AmountControl'

interface TextProps {
  info?: boolean
  stake?: boolean
}

const IconInfo = styled.img.attrs({ src: iconInfo })`
  width: 24px;
  height: 24px;
  justify-content: center;
  display: flex;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 7px;
  margin-top: 25px;
`

const StakeButton = styled.button`
  width: 260px;
  height: 44px;
  background: rgb(253, 46, 83);
  border-radius: 22px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('stake')`
    color: rgb(254, 255, 255);
    font-size: 14px;
    font-family: Montserrat;
    font-weight: bold;
    text-align: center;
    line-height: 18px;
  `};

  ${withModifiers('info')`
    color: rgb(86, 96, 105);
    font-size: 11px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
  `};
`

interface StakeFormProps {
  amount: string
  token: string
  penalty: number
  balance: StakingBalance
  setAmount: React.Dispatch<React.SetStateAction<string>>
  stakeToken: (
    lockType: WEB3_LOCK_TYPE,
    amount: string,
    token: string,
    fakeUnstake: boolean,
    events?: ContractEvents
  ) => any
}

const StakeForm = ({ token, amount, penalty, balance, setAmount, stakeToken }: StakeFormProps) => {
  const tokenBalance = get(balance, `${token.replace(' ', '_')}`)

  return (
    <Form>
      <AmountControl
        balance={balance}
        loading={false}
        setAmount={setAmount}
        amount={amount}
        token={token}
        type={AmountControlType.LOCK}
      />

      <FormGroup>
        <br />

        <StakeButton
          disabled={!tokenBalance.unlocked || !+amount || amount > tokenBalance.unlocked}
          onClick={() => {
            if (amount <= tokenBalance.unlocked && tokenBalance.unlocked) {
              stakeToken(WEB3_LOCK_TYPE.LOCK, amount, token, false)
            }
          }}
        >
          <Text stake>Stake tokens</Text>
        </StakeButton>

        <IconInfo />

        <Text info>You can unstake your tokens at anytime.</Text>
        <Text info>
          You can unstake immediately with a {penalty}% penalty or wait {COOLDOWN_PERIOD} days with no penalty.
        </Text>
      </FormGroup>
    </Form>
  )
}

export default StakeForm
