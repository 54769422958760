import { useEffect, useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil'
import api from 'util/api'

import { AccountState } from 'util/auth'
import { accountState, authState, countryState } from 'util/state'

const AccountSubscription = () => {
  const [auth, setAuth] = useRecoilState(authState)
  const [authAccount, setAuthAccount] = useRecoilState(accountState)
  const [firstLoad, setFirstLoad] = useState(false)
  const setCountry = useSetRecoilState(countryState)

  useEffect(() => {
    const fetchAuthAccount = async (authAccount: AccountState) => {
      if (authAccount.loading) {
        return
      }

      setAuthAccount({
        account: authAccount.account,
        loading: true
      })

      try {
        const { data } = await api.get('/v2/account?fields=email,id,stormxRewards,flags')
        if (data.success && auth.signedIn) {
          setAuthAccount({
            account: data.payload,
            loading: false
          })
          setCountry(data.meta.request.country)
        }
      } catch (ex) {
        setAuth({
          signedIn: false,
          token: undefined
        })
        setAuthAccount({
          account: undefined,
          loading: false
        })
      }
    }

    const interval = setInterval(() => {
      fetchAuthAccount(authAccount)
    }, 10000)
    if (!firstLoad) {
      setFirstLoad(true)
      fetchAuthAccount(authAccount)
    }

    return () => clearInterval(interval)
  }, [auth, authAccount, firstLoad, setAuth, setAuthAccount, setCountry])

  return null
}

export default AccountSubscription
