import axios, { AxiosRequestConfig } from 'axios'

import { API_URL } from 'util/const'
import store, { AuthStore } from 'util/store'

const api = axios.create({
  baseURL: API_URL
})

api.interceptors.request.use(async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const auth = (await store.getItem('auth')) as AuthStore

  const headers = config.headers as Record<string, string>
  if (auth['aff-id']) {
    headers['aff-id'] = auth['aff-id']
  }
  if (auth.devicename) {
    headers.devicename = auth.devicename
  }
  if (auth.token) {
    headers.token = auth.token
  }
  if (auth.udid) {
    headers.udid = auth.udid
  }
  if (auth.version) {
    headers.version = auth.version
  }

  return config
})

export default api
