import { Modal } from 'rsuite'
import styled from 'styled-components'

import { responsiveWidthM } from 'util/const'

import CloseImage from './images/close-min.png'

export default styled(Modal)`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
  }

  & > .rs-modal-dialog > .rs-modal-content {
    & > .rs-modal-body {
      margin-bottom: 15px;
    }

    & > .rs-modal-header > .rs-modal-header-close {
      &:before {
        display: none;
      }

      background: transparent url(${CloseImage}) 0 0 no-repeat;
      background-size: 33px 33px;
      width: 33px;
      height: 33px;
      padding: 5px;
    }

    background-size: 213px 76px;
    border-radius: 34px;
    box-shadow: inset 0px 0px 0px 1px rgba(116, 111, 127, 0.05), 0px 1px 2px 0px rgba(0, 0, 0, 0.1),
      0px 6px 16px 0px rgba(0, 0, 0, 0.15);
  }
`
