import styled from 'styled-components'
import { withModifiers } from 'util/styles'
import { responsiveWidthM } from 'util/const'
import { ButtonGroup, InputNumber } from 'rsuite'
import IconInfoImage from 'components/staking/images/icons_info.png'

interface TextProps {
  time?: boolean
  bold?: boolean
  reward?: boolean
  timeSelected?: boolean
  estimatedReward?: boolean
  estimatedRewardAmount?: boolean
}

interface RowContainerProps {
  column?: boolean
  estimatedReward?: boolean
  withSpaceBetween?: boolean
}

interface TimeButtonProps {
  selected?: boolean
}

interface HeaderProps {
  isSmallScreen?: boolean
}

export const Wrapper = styled.div`
  min-height: 288px;
  background: rgb(255, 255, 255);
  border-radius: 32px;
  border: 1px solid rgb(232, 232, 232);
  padding: 40px 30px;
  margin-top: 30px;
  margin-left: 30%;
  margin-right: 30%;

  @media only screen and (max-width: ${responsiveWidthM}px) {
    margin-left: 2.6%;
    margin-right: 2.6%;
  }
`

export const Header = styled.div<HeaderProps>`
  color: #1d1d1d;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: bold;
  height: 22px;
  line-height: 22px;

  ${withModifiers('isSmallScreen')`
    margin-bottom: 12px;
  `};
`

export const AmountInput = styled(InputNumber)`
  & > input {
    font-size: 16px;
  }

  & > .rs-input-number-btn-group-vertical {
    display: none;
  }

  & > .rs-input-group-addon {
    color: rgb(38, 38, 38);
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 16px;
    text-align: right;
    background-color: transparent;
    padding-right: 25px;
  }

  width: 100% !important;
  border-radius: 8px;
  border: 1px solid rgb(250, 46, 83);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  height: 60px;
  color: rgb(44, 37, 60);
  font-family: Adelle;
  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  padding-left: 10px;
  margin: 25px 0 15px 0;
`

export const TypeButtonGroup = styled(ButtonGroup)`
  height: 40px;
  margin-bottom: 40px;
`

export const IconInfo = styled.img.attrs({ src: IconInfoImage })`
  width: 20px;
  height: 20px;
  margin-top: -3px;
  margin-left: 5px;
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('time')`
    color: rgb(29, 29, 29);
    font-size: 13px;
    font-family: Montserrat;
    font-weight: bold;
    text-align: center;
    line-height: 16px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    ${withModifiers('timeSelected')`
      color: rgb(255, 255, 255);
    `};
  `};

  ${withModifiers('estimatedReward')`
    color: rgb(86, 96, 105);
    font-size: 11px;
    font-family: Montserrat;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    line-height: 14px;
    text-align: left;
  `};

  ${withModifiers('reward')`
    color: rgb(86, 96, 105);
    font-size: 17px;
    font-family: SFMono;
    font-weight: bold;

    ${withModifiers('bold')`
      color: rgb(29, 29, 29);
    `};
  `};

  ${withModifiers('estimatedRewardAmount')`
    color: rgb(253, 46, 83);
    font-size: 17px;
    font-family: SFMono;
    font-weight: bold;
    text-align: right;
  `};
`

export const TimeButton = styled.div<TimeButtonProps>`
  width: 100px;
  height: 34px;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 17px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    opacity: 0.5;
  }

  ${withModifiers('selected')`
    background: #2b2969;
  `};
`

export const RowContainer = styled.div<RowContainerProps>`
  flex-direction: row;
  align-items: center;
  display: flex;

  ${withModifiers('withSpaceBetween')`
    justify-content: space-between;
  `};

  ${withModifiers('column')`
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `};

  ${withModifiers('estimatedReward')`
    margin-top: 15px;
  `};
`
