import styled from 'styled-components'
import IconInfoImage from 'components/staking/images/icons_info.png'
import { Col, Row } from 'rsuite'
import { withModifiers } from 'util/styles'
import { responsiveWidthM, responsiveWidthL } from 'util/const'

interface CustomButtonProps {
  disabled?: boolean
}

interface TextProps {
  section?: boolean
}

interface ColProps {
  addborder?: boolean
}

export const EarningsWrapper = styled.div`
  position: relative;
`

export const SectionRow = styled(Row)`
  @media only screen and (max-width: ${responsiveWidthL}px) {
    margin-top: 15px;
  }
`

export const EarningsCol = styled(Col)<ColProps>`
  ${withModifiers('addborder')`
    border-right-style: solid;
    border-right-color: rgb(231, 231, 231);
    height: 90px;
  `};
`

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background: #e7e7e7;
  margin-top: 25px;
  margin-bottom: 25px;
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('section')`
    color: rgb(86, 96, 105);
    font-size: 11px;
    font-family: Montserrat;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    line-height: 14px;
    display: block;
    margin-bottom: 4px;
  `};
`

export const SectionText = styled.div`
  color: rgb(29, 29, 29);
  font-family: SpaceMono;
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
`

export const CustomButtonWrapper = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    margin-top: 5px;
  }

  text-align: center;
  margin-top: 15px;
`

export const CustomButton = styled.button<CustomButtonProps>`
  display: inline-block;
  color: rgb(250, 46, 83);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0px;
  width: 110px;
  height: 30px;
  border: 1px solid rgb(253, 46, 83);
  border-radius: 17px;
  text-align: center;
  background: white;
  padding: 0 !important;
  cursor: pointer;
  line-height: 16px;

  &:hover {
    opacity: 0.5;
  }

  ${withModifiers('disabled')`
    background: rgb(234, 237, 239);
    border: 1px solid rgb(234, 237, 239);
    cursor: default;
    color: #1d1d1d50;

    &:hover {
      opacity: 1;
    }
  `};
`

export const IconInfo = styled.img.attrs({ src: IconInfoImage })`
  width: 20px;
  height: 20px;
  margin-top: -3px;
  margin-left: 5px;
`
