import BigNumber from 'bignumber.js'
import { format } from 'date-fns'
import isString from 'lodash/isString'
import startCase from 'lodash/startCase'

const BASE_TITLE = document.getElementsByTagName('head')[0].getElementsByTagName('title')[0].innerText

export const clearLocationHash = () => {
  try {
    window.history.replaceState(null, '', ' ')
  } catch (ex) {}
}

export const formatDate = (date: Date | string): string => {
  if (isString(date)) {
    date = new Date(date)
  }
  return format(date, 'yyyy-mm-dd HH:mm:ss')
}

export const getRandomUserId = () => {
  let result = ''
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < 60; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length))
  }
  return result
}

export const numberWithCommas = (x: number | string): string => {
  try {
    const split = x.toString().split('.')
    return `${split[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${split[1] ? `.${split[1]}` : ''}`
  } catch (ex) {
    return x.toString()
  }
}

export const obfuscateWallet = (wallet: string) => `${wallet.substr(0, 6)}...${wallet.substr(-4)}`

export const parseCamelCase = (str: string): string => startCase(str)

export const parseParams = (value: string): Record<string, string> =>
  value.length
    ? value
        .replace(value.startsWith('#') ? '#' : '', '')
        .replace(value.startsWith('?') ? '?' : '', '')
        .split('&')
        .map((v) => v.split('='))
        .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {})
    : {}

export const removeSpecialChars = (value: string) => (value ? value.replace(/[^A-Za-z0-9_ ]/g, '') : '')

export const serializeQuery = (obj: { [key: string]: any }): string => {
  var str = []
  for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`)
    }
  }
  return str.join('&')
}

export const sleep = (duration: number) => new Promise((resolve) => setTimeout(resolve, duration))

export const toUSD = (amount: number | BigNumber, rate: number) => new BigNumber(amount).multipliedBy(rate).toFixed(2)

export const setTitle = (title: string): string => (document.title = `${BASE_TITLE} - ${title}`)
