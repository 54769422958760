import styled from 'styled-components'
import stmxLogo from 'components/staking/images/stmx-filled.png'
import athLogo from 'components/staking/images/ath-filled.png'
import { responsiveWidthM, responsiveWidthL } from 'util/const'
import { withModifiers } from 'util/styles'
import { Col, Row } from 'rsuite'

interface TextProps {
  link?: boolean
  token?: boolean
  stake?: boolean
  disabled?: boolean
  description?: boolean
  statisticTitle?: boolean
  statisticValue?: boolean
}

interface RowContainerProps {
  column?: boolean
  statistics?: boolean
  withSpaceBetween?: boolean
}

interface CardProps {
  first?: boolean
}

interface LeftContentProps {
  full?: boolean
}

interface StakeButtonProps {
  full?: boolean
  disabled?: boolean
}

interface StatisticItemProps {
  second?: boolean
}

interface LineProps {
  isSmallScreen?: boolean
}

export const SectionRow = styled(Row)``

export const Column = styled(Col)``

export const Container = styled.div<CardProps>`
  width: auto;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 32px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;

  @media only screen and (max-width: ${responsiveWidthM}px) {
    margin-left: 2.6%;
    margin-right: 2.6%;
  }

  ${withModifiers('first')`
    margin-top: 80px;
  `};
`

export const LeftContent = styled.div<LeftContentProps>`
  padding-top: 30px;
  padding-bottom: 39px;
  width: 75%;
  margin-right: 30px;
  margin-left: 18px;

  ${withModifiers('full')`
    width: 100%;
    margin-right: 18px;
  `};
`

export const RightContent = styled.div`
  width: 30%;
  background: #2b2969;
  justify-content: center;
  align-items: center;
  display: flex;
  border-bottom-right-radius: 32px;
  border-top-right-radius: 32px;
`

export const BottomContent = styled.div`
  width: 100%;
  background: #2b2969;
  justify-content: center;
  align-items: center;
  display: flex;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
  border-top-width: 1px;
  border-top-color: rgb(231, 231, 231);
  border-top-style: solid;
  padding-top: 12px;
  padding-bottom: 12px;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
`

export const VerticalLine = styled.div`
  width: 1px;
  background: rgb(231, 231, 231);
  height: 65pxpx;
`

export const RowContainer = styled.div<RowContainerProps>`
  flex-direction: row;
  display: flex;

  ${withModifiers('withSpaceBetween')`
    justify-content: space-between;
  `};

  ${withModifiers('column')`
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `};

  ${withModifiers('statistics')`
    width: 100%;
    align-items: flex-end;
  `};
`

export const StmxLogo = styled.img.attrs({
  src: stmxLogo
})`
  height: 40px;
  width: 40px;
  margin-right: 12px;
`

export const AthLogo = styled.img.attrs({
  src: athLogo
})`
  height: 40px;
  width: 40px;
  margin-right: 12px;
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('token')`
    color: rgb(29, 29, 29);
    font-size: 17px;
    font-family: Montserrat;
    font-weight: bold;
    line-height: 22px;
    width: 100%;
  `};

  ${withModifiers('description')`
    color: rgb(29, 29, 29);
    font-size: 15px;
    font-family: Adelle;
    font-weight: normal;
    text-decoration: underline;
  `};

  ${withModifiers('stake')`
    color: rgb(254, 255, 255);
    font-size: 14px;
    font-family: Montserrat;
    font-weight: bold;
    text-align: center;
    line-height: 18px;
    cursor: pointer;
  `};

  ${withModifiers('statisticTitle')`
    color: rgb(255, 255, 255);
    font-size: 11px;
    font-family: Montserrat;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    line-height: 14px;
  `};

  ${withModifiers('statisticValue')`
    color: rgb(255, 255, 255);
    font-size: 17px;
    font-family: SFMono;
    font-weight: bold;
    text-align: center;
    padding-top: 6px;
  `};

  ${withModifiers('link')`
    cursor: pointer;
    
    &:hover {
      opacity: 0.5;
    }
  `};

  ${withModifiers('disabled')`
    color: #1d1d1d50;
    cursor: default;

    &:hover {
      opacity: 1;
    }
  `};
`

export const Line = styled.div<LineProps>`
  width: 100%;
  height: 1px;
  background: #e7e7e7;
  margin-top: 18px;
  margin-bottom: 35px;

  ${withModifiers('isSmallScreen')`
    margin-bottom: 15px;
  `};
`

export const StakeButton = styled.div<StakeButtonProps>`
  height: 44px;
  background: rgb(253, 46, 83);
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;

  @media only screen and (max-width: ${responsiveWidthL}px) {
    margin-top: 15px;
  }

  ${withModifiers('full')`
    width: 100%;
  `};

  &:hover {
    opacity: 0.5;
  }

  ${withModifiers('disabled')`
    background: #eaedef;
    cursor: default;

    &:hover {
      opacity: 1;
    }
  `};
`

export const StatisticItem = styled.div<StatisticItemProps>`
  width: 95%;
  background: linear-gradient(-90deg, rgb(43, 41, 105) 0%, rgb(29, 27, 77) 100%);
  border-radius: 18px;

  flex-direction: row;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 19px;
  padding-bottom: 18px;

  ${withModifiers('second')`
    margin-top: 12px;
  `};
`
