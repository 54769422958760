import { useEffect, useState, Fragment } from 'react'
import Zendesk from 'react-zendesk'
import { useRecoilStateLoadable } from 'recoil'
import { Loader } from 'rsuite'
import defer from 'lodash/defer'
import 'rsuite/lib/styles/themes/default/index.less'
import Staking from 'components/staking'
import Debug from 'components/common/Debug'
import Environment from 'components/common/Environment'
import { ZENDESK_KEY } from 'util/const'
import { authSelector } from 'util/state'
import './App.less'

const App = () => {
  const [error, setError] = useState('')
  const [loaded, setLoaded] = useState(false)
  const [auth, setAuth] = useRecoilStateLoadable(authSelector)

  useEffect(() => {
    if (auth.state === 'hasError') {
      console.error(auth.contents)
      setError(auth.contents.message || auth.contents.name)
    } else if (!loaded && auth.state === 'hasValue') {
      setAuth(auth.contents)
      defer(() => setLoaded(true))
    }
  }, [auth, loaded, setAuth, setLoaded])

  if (error) {
    return (
      <Fragment>
        An error occured, please return to StormX.
        <br />
        If this problem continues please get in touch with our support:
        <br />
        <strong>{error}</strong>
      </Fragment>
    )
  } else if (!loaded) {
    return <Loader center size='lg' />
  }

  return (
    <div className='App'>
      <Debug />
      <Environment />
      <Staking />
      {ZENDESK_KEY && <Zendesk zendeskKey={ZENDESK_KEY} />}
    </div>
  )
}

export default App
