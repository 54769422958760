import React from 'react'
import { Button } from 'rsuite'
import styled from 'styled-components'

import ProgressBox from './ProgressBox'
import ProgressContainer from './ProgressContainer'
import ProgressTick from './ProgressTick'

import BuyStmx from '../BuyStmx'

interface Step4Props {
  isDone: boolean
  setStartStaking: React.Dispatch<React.SetStateAction<boolean>>
  accountId?: string
  balance?: number
  email?: string
  isLoading?: boolean
  previousDone?: boolean
  walletAddress?: string
}

const InfoText = styled.div`
  color: rgb(38, 38, 38);
  font-family: Adelle;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0px;
  margin-bottom: 20px;
`

const StartStakingButton = styled(Button)`
  margin-top: 35px;
  color: rgb(255, 255, 255);
  font-family: Adelle;
  font-size: 14px;
  font-weight: normal;
  height: 20px;
  letter-spacing: 0px;
  text-align: center;
  width: 91px;
  border-radius: 4px;
  background: rgb(250, 46, 83);
  height: 42px;
  width: 156px;
`

const Step4ProgressContainer = styled(ProgressContainer)`
  &:before {
    display: inline-block;
    content: ' ';
    position: absolute;
    background: rgb(255, 255, 255);
    top: 40px;
    left: 00px;
    width: 40px;
    bottom: 0;
  }
`

const Step3 = ({ accountId, balance, email, isDone, previousDone, setStartStaking, walletAddress }: Step4Props) => (
  <Step4ProgressContainer>
    <ProgressTick completed={isDone} text='3' />
    <ProgressBox completed={isDone} previousDone={previousDone} text='Get some STMX or ATH'>
      <InfoText>
        You need STMX or ATH to Stake
        <br />
        Your wallet has {balance} STMX
        <br />
        <br />
        Buy STMX from our partners or earn it in the app when you shop.
      </InfoText>

      <BuyStmx accountId={accountId} block email={email} walletAddress={walletAddress} />

      <StartStakingButton
        appearance='primary'
        onClick={() => {
          setStartStaking(true)
          window.scrollTo(0, 0)
        }}
      >
        Start Staking!
      </StartStakingButton>
    </ProgressBox>
  </Step4ProgressContainer>
)

export default Step3
