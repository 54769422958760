import React from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'

import { isStaging } from 'util/const'
import { debugState } from 'util/state'

const Wrapper = styled.div`
  position: fixed;
  z-index: 5010;
  top: 0;
  left: 0;
  text-align: left;
  font-weigh: bold;
  font: sans-serif;
  color: #000;
  background: #fff;
  padding: 10px;
  opacity: 0.8;
`

const Debug = () => {
  const debug = useRecoilValue(debugState)

  if (!isStaging || !debug) {
    return null
  }

  return <Wrapper dangerouslySetInnerHTML={{ __html: debug.split('\n').join('<br />') }} />
}

export default Debug
