import React from 'react'
import BigNumber from 'bignumber.js'
import StakeForm from '../StakeForm'
import ThemedModal from '../../ThemedModal'
import { get } from 'lodash'
import { Modal } from 'rsuite'
import { StakingBalance } from '../earnings/index'
import { ContractEvents, WEB3_LOCK_TYPE } from 'util/web3'
import { Text, ModalHeader, CenterContainer } from './components/index'

interface StakeDialogProps {
  token: string
  amount: string
  penalty: number
  balance: StakingBalance
  showStakeDialog: boolean
  setShowStakeDialog: (show: boolean) => void
  setAmount: React.Dispatch<React.SetStateAction<string>>
  stakeToken: (
    lockType: WEB3_LOCK_TYPE,
    amount: string,
    token: string,
    fakeUnstake: boolean,
    events?: ContractEvents
  ) => any
}

const StakeDialog = ({
  token,
  amount,
  balance,
  penalty,
  setAmount,
  stakeToken,
  showStakeDialog,
  setShowStakeDialog
}: StakeDialogProps) => {
  const tokenBalance = get(balance, `${token.replace(' ', '_')}`)

  return (
    <ThemedModal overflow={false} show={showStakeDialog} onExit={() => setAmount('0')}>
      <Modal.Header onHide={() => setShowStakeDialog(false)}>
        <ModalHeader>Stake your {token} tokens</ModalHeader>
      </Modal.Header>
      <div>
        <CenterContainer>
          <Text unlocked>
            You have {BigNumber(tokenBalance.unlocked).toString()} {token} tokens to stake.
          </Text>
        </CenterContainer>

        <StakeForm
          token={token}
          amount={amount}
          balance={balance}
          penalty={penalty}
          setAmount={setAmount}
          stakeToken={stakeToken}
        />
      </div>
    </ThemedModal>
  )
}

export default StakeDialog
