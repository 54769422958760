import { useEffect, useState, Fragment } from 'react'
import { isMobile } from 'react-device-detect'
import { useRecoilValue } from 'recoil'
import WebFont from 'webfontloader'

import BigNumber from 'bignumber.js'

import { detection } from 'util/browser'
import { accountState, authState } from 'util/state'
import { parseParams, setTitle } from 'util/utils'

import Instructions from './instructions'
import Welcome from './welcome'
import Stake from './stake'

import AccountSubscription from './AccountSubscription'
import SummarySubscription from './SummarySubscription'
import Web3Subscription from './Web3Subscription'

BigNumber.config({ EXPONENTIAL_AT: 1e9 })

const Staking = () => {
  setTitle('Staking')

  const [fontsLoaded, setFontsLoaded] = useState(false)
  const [letsGo, setLetsGo] = useState(false)
  const [startStaking, setStartStaking] = useState(false)

  const auth = useRecoilValue(authState)
  const authAccount = useRecoilValue(accountState)

  const showJustTrustWalletWC = parseParams(window.location.search)['tb'] === '1'
  const walletBrowser = parseParams(window.location.search)['wb'] === '1'
  const walletBrowserReady = walletBrowser && !!authAccount?.account

  const isBrowserSupported = detection.isBrave || detection.isChrome || detection.isEdgeChromium || detection.isOpera
  const isMobileBrowser = isMobile
  const isLoading = !fontsLoaded || (!!auth.signedIn && !!authAccount.loading) || (walletBrowser && !walletBrowserReady)

  const isAccountCreated = !!auth.signedIn
  const isWalletConnected = isAccountCreated && !!authAccount?.account?.stormxRewards
  const hasTokens = isWalletConnected && !!authAccount?.account?.stormxRewards?.balance
  const canStake = isAccountCreated && isWalletConnected && hasTokens
  const showStake = (isAccountCreated && (canStake || startStaking)) || (walletBrowser && isWalletConnected)

  if (!letsGo && walletBrowser && walletBrowserReady) {
    setLetsGo(true)
  }

  useEffect(() => {
    WebFont.load({
      custom: {
        families: ['Adelle', 'SpaceMono', 'SFMono'],
        urls: ['/fonts/Adelle.css', '/fonts/SpaceMono.css', '/fonts/SFMono.css']
      },
      google: {
        api: 'https://fonts.googleapis.com/css2',
        families: ['Montserrat:wght@400;500;600;700;800']
      },
      active: () => {
        setFontsLoaded(true)
      },
      inactive: () => {
        // load the page even when fonts couldn't load
        setFontsLoaded(true)
      }
    })
  }, [])

  return (
    <Fragment>
      {!hasTokens && isAccountCreated && <AccountSubscription key='account-subscription' />}
      {isWalletConnected && <SummarySubscription key='summary-subscription' />}
      <Web3Subscription onDisconnect={() => setLetsGo(false)} />

      {(!letsGo || (!letsGo && isLoading)) && (
        <Welcome
          isBrowserSupported={isBrowserSupported}
          isLoading={isLoading}
          isMobileBrowser={isMobileBrowser}
          setLetsGo={setLetsGo}
          showJustTrustWalletWC={showJustTrustWalletWC}
          walletBrowser={walletBrowser}
        />
      )}

      {letsGo &&
        (showStake ? (
          <Stake isMobileBrowser={isMobileBrowser} />
        ) : (
          <Instructions
            authAccount={authAccount}
            isAccountCreated={isAccountCreated}
            isMobileBrowser={isMobileBrowser}
            isWalletConnected={isWalletConnected}
            setStartStaking={setStartStaking}
          />
        ))}
    </Fragment>
  )
}

export default Staking
