import { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { Button, Form, HelpBlock, Input, Schema } from 'rsuite'
import styled from 'styled-components'

import api from 'util/api'
import { DEFAULT_AUTH } from 'util/auth'
import { responsiveWidthS, responsiveWidthM } from 'util/const'
import { accountState, authSelector } from 'util/state'

import ProgressBox from './ProgressBox'
import ProgressContainer from './ProgressContainer'
import ProgressTick from './ProgressTick'

const InputText = styled(Input)`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    width: 100%;
  }

  @media only screen and (max-width: ${responsiveWidthS}px) {
    margin-right: 0;
  }

  & > input {
    font-size: 20px;
    width: 100%;
  }

  font-family: Adelle;
  background: rgb(255, 255, 255);
  border-radius: 0px;
  border: 1px solid rgb(181, 189, 195);
  height: 40px;
  width: 270px;
  margin-right: 20px;
  display: inline-block;
`

const InputTextNarrow = styled(InputText)`
  width: 160px;
`

const SubmitButton = styled(Button)`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    width: 100px;
  }

  border-radius: 30px;
  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
  padding: 9px 0 !important;
  width: 140px;
  display: inline-block;
  margin-right: 10px;
`

const SubmitButtonGray = styled(SubmitButton)`
  background-color: gray;
`

const Label = styled.div`
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0px;
  line-height: 20px;
  margin-bottom: 15px;
`

const ToCText = styled.div`
  font-family: Adelle;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 20px;
  margin-top: 15px;
`

const ToCLink = styled.a.attrs({
  href: 'https://stormx.io/terms-of-use',
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  color: rgb(253, 46, 83);
`

const ButtonsWrapper = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    display: block;
    margin-top: 10px;
  }

  display: inline-block;
`

const ErrorMessage = styled(HelpBlock)`
  color: red;
  font-family: Montserrat;
  font-size: 12px;
  margin-top: 10px;
`

enum Step2Stage {
  INIT = 0,
  NO_ACCOUNT = 1,
  NOT_LOGGED_IN = 2,
  ALL_DONE = 3
}

interface Step2Props {
  isDone: boolean
  isMobileBrowser: boolean
  previousDone: boolean
  showForm: boolean
}

const model = Schema.Model({
  code: Schema.Types.StringType().minLength(4).maxLength(8).containsNumber().isRequired('This field is required.'),
  email: Schema.Types.StringType().isEmail('Please enter a valid email address.').isRequired('This field is required.')
})

const Step1 = (props: Step2Props) => {
  const setAuth = useSetRecoilState(authSelector)
  const setAuthAccount = useSetRecoilState(accountState)

  const [code, setCode] = useState('')
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [stage, setStage] = useState(Step2Stage.INIT)
  const [token, setToken] = useState('')

  const validation = model.check({ code, email })

  useEffect(() => {
    setStage(props.showForm ? Step2Stage.NO_ACCOUNT : Step2Stage.INIT)
  }, [props])

  const sendTheEmail = async () => {
    if (!email || validation.email.hasError) {
      return
    }

    setLoading(true)

    try {
      setAuth({
        'aff-id': DEFAULT_AUTH['aff-id'],
        devicename: DEFAULT_AUTH['devicename'],
        signedIn: false,
        token: undefined,
        udid: DEFAULT_AUTH['udid'],
        version: DEFAULT_AUTH['version']
      })
      const { data: sessionData } = await api.get('/session')
      const token = sessionData.payload.token
      if (sessionData.success) {
        setAuth({
          signedIn: false,
          token
        })
      }

      const { data: sendData } = await api.post(
        '/v2/auth/email/otp/send',
        { email },
        {
          headers: { token }
        }
      )
      if (sendData.success) {
        setToken(token)
        setStage(Step2Stage.NOT_LOGGED_IN)
        setCode('')
      }
    } catch (ex) {
      setError('An error occured when trying to authorize, please try again later or contact our support')
    } finally {
      setLoading(false)
    }
  }

  const sendTheCode = async (freshCode = code, force = false) => {
    if (!force && (!freshCode || validation.code.hasError)) {
      return
    }

    setLoading(true)

    try {
      const { data } = await api.post(
        '/v2/auth/email/otp/verify',
        {
          code: freshCode,
          email
        },
        {
          headers: { token }
        }
      )
      if (data.success) {
        setStage(Step2Stage.ALL_DONE)
        setAuth({
          cached: true,
          signedIn: true,
          token
        })
        setAuthAccount({
          account: data.payload.account,
          loading: false
        })
      }
    } catch (err) {
      // @ts-ignore
      if (err?.response?.data?.code === 2070) {
        setError('Invalid verification code entered, please try again')
      } else {
        setError('An error occured when verifying the authorization, please try again later or contact our support')
      }
    } finally {
      setLoading(false)
    }
  }

  const cancelSendingCode = () => {
    setEmail('')
    setStage(Step2Stage.NO_ACCOUNT)
  }

  let formElement = null
  if (!props.isDone) {
    if (stage === Step2Stage.NO_ACCOUNT) {
      formElement = (
        <Form model={model}>
          <Label>Please enter your email address. We will send a verification code.</Label>
          <InputText
            disabled={loading}
            name='email'
            onChange={(email: string) => {
              setEmail(email)
              setError('')
            }}
            placeholder='satoshi@nakamoto.com'
            value={email}
          />
          <ButtonsWrapper>
            <SubmitButton
              appearance='primary'
              disabled={validation.email.hasError}
              loading={loading}
              onClick={() => {
                sendTheEmail()
                setError('')
              }}
              type='submit'
            >
              Send
            </SubmitButton>
          </ButtonsWrapper>
          {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        </Form>
      )
    } else if (stage === Step2Stage.NOT_LOGGED_IN) {
      formElement = (
        <Form model={model}>
          <Label>Check your email for verification code and enter it below.</Label>
          <InputTextNarrow
            disabled={loading}
            name='code'
            onChange={(code: string) => {
              code = code.replace(/\D/g, '')
              setCode(code)
              if (code.length === 6) {
                sendTheCode(code, true)
              }
              setError('')
            }}
            placeholder='123456'
            value={code}
          />
          <ButtonsWrapper>
            <SubmitButton
              appearance='primary'
              disabled={validation.code.hasError}
              loading={loading}
              onClick={() => {
                sendTheCode()
                setError('')
              }}
              type='submit'
            >
              Verify
            </SubmitButton>
            {!loading && (
              <SubmitButtonGray
                onClick={() => {
                  cancelSendingCode()
                  setError('')
                }}
              >
                Cancel
              </SubmitButtonGray>
            )}
          </ButtonsWrapper>
          {error ? <ErrorMessage>{error}</ErrorMessage> : null}
        </Form>
      )
    }
  }

  return (
    <ProgressContainer>
      <ProgressTick completed={props.isDone} isLoading={loading} text='1' />
      <ProgressBox completed={props.isDone} previousDone={props.previousDone} text='Login or Signup'>
        {formElement}
        <ToCText>
          By signing up, I agree to <ToCLink>StormX's Terms of Service and Privacy policy</ToCLink>.
        </ToCText>
      </ProgressBox>
    </ProgressContainer>
  )
}

export default Step1
