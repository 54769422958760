import { useEffect, useState, Fragment } from 'react'
import { useRecoilValue } from 'recoil'
import styled from 'styled-components'

import api from 'util/api'
import { isProduction, isStaging, responsiveWidthM, MOONPAY_API_KEY } from 'util/const'
import { countryState } from 'util/state'

import BinanceImage from './images/img-binance-min.png'
import BittrexImage from './images/img-bittrex-min.png'
import MastercardImage from './images/img-mastercard-min.png'
import MoonPayImage from './images/img-moonpay-min.png'
import TrustWalletImage from './images/img-trustwallet-min.png'
import UniSwapImage from './images/img-uniswap-min.png'
import UpBitImage from './images/img-upbit-min.png'
import VisaImage from './images/img-visa-min.png'

export interface BuyStmxProps {
  accountId?: string
  alwaysCentered?: boolean
  block?: boolean
  email?: string
  walletAddress?: string
}

export interface BuyStmxComonentProps {
  alwaysCentered?: boolean
  block?: boolean
}

const BINANCE_URL = 'https://www.binance.com'
const BITTREX_URL = 'https://bittrex.com'
const TRUSTWALLET_URL = 'https://trustwallet.com'
const UNISWAP_URL = 'https://uniswap.org'
const UPBIT_URL = 'https://upbit.com'

const Wrapper = styled.div`
  ${({ alwaysCentered }: BuyStmxComonentProps) => (alwaysCentered ? `text-align: center;` : '')}
  ${({ block }: BuyStmxComonentProps) =>
    !block
      ? `display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: wrap;`
      : ''}
`

const PreferredPartnerSection = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    text-align: ${({ alwaysCentered }: BuyStmxComonentProps) => (alwaysCentered ? `center` : 'left')};
    width: 100%;
  }

  width: ${({ block }: { block?: boolean }) => (!block ? '415px' : '100%')};
  display: inline-block;
  margin-bottom: 30px;
`

const SectionButtonWrapper = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    display: block;
  }

  display: inline-block;
`

const SectionButton = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  height: 44px;
  width: 156px;
  text-align: center;
  display: inline-block;
  cursor: pointer;
  margin: 5px 0;
`

const SectionButtonMoonPay = styled.img.attrs({ src: MoonPayImage })`
  width: 80px;
  margin: 9px 0;
`

const BuyWith = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    ${({ alwaysCentered }: BuyStmxComonentProps) => (alwaysCentered ? `text-align: center;` : '')}
    margin-top: 10px;
    margin-left: 0;
    display: block;
    width: 100%;
  }

  position: relative;
  color: rgb(0, 0, 0);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0px;
  width: 200px;
  display: inline-block;
  margin-left: 20px;
`

const MoonPayBuyWithVisa = styled.img.attrs({ src: VisaImage })`
  width: 43px;
  margin: -2px 10px 0 10px;
`

const MoonPayBuyWithMastercard = styled.img.attrs({ src: MastercardImage })`
  width: 32px;
`

const SectionButtonBinance = styled.img.attrs({ src: BinanceImage })`
  width: 100px;
  margin-top: 11px;
`

const SectionButtonUpBit = styled.img.attrs({ src: UpBitImage })`
  width: 70px;
  margin-top: 13px;
`

const SectionButtonBittrex = styled.img.attrs({ src: BittrexImage })`
  width: 100px;
  margin-top: 11px;
`

const SectionButtonTrustWallet = styled.img.attrs({ src: TrustWalletImage })`
  width: 112px;
  margin-top: 9px;
`

const SectionButtonUniSwap = styled.img.attrs({ src: UniSwapImage })`
  width: 99px;
  margin-top: 9px;
`

const SectionsWrapperLeft = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    width: 100%;
    margin-top: 10px;
    height: auto;
  }

  &:before {
    @media only screen and (max-width: ${responsiveWidthM}px) {
      display: none;
    }

    display: inline-block;
    background: rgba(0, 0, 0, 0.15);
    width: 1px;
    content: ' ';
    position: absolute;
    width: 1px;
    top: 27px;
    right: 0;
    bottom: 5px;
  }

  position: relative;
  display: inline-block;
  width: 205px;
  vertical-align: top;
`

const SectionsWrapperRight = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    width: 100%;
    margin-left: 0;
    margin-top: 30px;
    height: auto;
  }

  display: inline-block;
  width: 160px;
  margin-left: 50px;
  vertical-align: top;
`

const InfoHeader = styled.div`
  color: rgb(86, 96, 105);
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  height: 12px;
  letter-spacing: 0px;
  line-height: 12px;
  margin-bottom: 10px;
`

const InfoHeaderUniSwap = styled(InfoHeader)`
  margin-top: 32px;
`

const BuyStmx = (props: BuyStmxProps) => {
  const country = useRecoilValue(countryState)
  const [moonpayUrl, setMoonpayUrl] = useState('')

  useEffect(() => {
    const getMoonpayUrl = async () => {
      const subdomain = isStaging || isProduction ? 'buy' : 'buy-staging'
      let moonpayUrl = `https://${subdomain}.moonpay.com?apiKey=${MOONPAY_API_KEY}&colorCode=%23fa2e53&currencyCode=STMX`

      if (props.accountId) {
        moonpayUrl += `&externalCustomerId=${props.accountId}`
      }
      if (props.email) {
        moonpayUrl += `&email=${encodeURIComponent(props.email)}`
      }
      if (props.walletAddress) {
        moonpayUrl += `&showWalletAddressForm=true&walletAddress=${props.walletAddress}`
      }

      try {
        const { data } = await api.get('/moonpay', {
          params: {
            originalUrl: btoa(moonpayUrl)
          }
        })
        setMoonpayUrl(data.payload.url)
      } catch (ex) {
        setMoonpayUrl('')
      }
    }

    getMoonpayUrl()
  }, [props.accountId, props.email, props.walletAddress])

  let preferredPartner
  if (country === 'US') {
    // removed voyager
    preferredPartner = null
  } else {
    preferredPartner = (
      <Fragment>
        <SectionButton onClick={() => window.open(moonpayUrl)}>
          <SectionButtonMoonPay />
        </SectionButton>
        <BuyWith alwaysCentered={props.alwaysCentered}>
          Buy with
          <MoonPayBuyWithVisa />
          <MoonPayBuyWithMastercard />
        </BuyWith>
      </Fragment>
    )
  }

  return (
    <Wrapper alwaysCentered={props.alwaysCentered} block={props.block}>
      {preferredPartner && (
        <PreferredPartnerSection alwaysCentered={props.alwaysCentered} block={props.block}>
          <InfoHeader>Preferred Partner</InfoHeader>
          {preferredPartner}
        </PreferredPartnerSection>
      )}

      <SectionsWrapperLeft>
        <InfoHeader>Major Crypto Exchanges</InfoHeader>
        <SectionButtonWrapper>
          <SectionButton onClick={() => window.open(BINANCE_URL)}>
            <SectionButtonBinance />
          </SectionButton>
        </SectionButtonWrapper>
        <SectionButtonWrapper>
          <SectionButton onClick={() => window.open(UPBIT_URL)}>
            <SectionButtonUpBit />
          </SectionButton>
        </SectionButtonWrapper>
        <SectionButtonWrapper>
          <SectionButton onClick={() => window.open(BITTREX_URL)}>
            <SectionButtonBittrex />
          </SectionButton>
        </SectionButtonWrapper>
      </SectionsWrapperLeft>

      <SectionsWrapperRight>
        <InfoHeader>Crypto Wallet</InfoHeader>
        <SectionButton onClick={() => window.open(TRUSTWALLET_URL)}>
          <SectionButtonTrustWallet />
        </SectionButton>
        <InfoHeaderUniSwap>Swap Crypto For STMX</InfoHeaderUniSwap>
        <SectionButton onClick={() => window.open(UNISWAP_URL)}>
          <SectionButtonUniSwap />
        </SectionButton>
      </SectionsWrapperRight>
    </Wrapper>
  )
}

export default BuyStmx
