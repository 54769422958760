import { IWalletConnectProviderOptions } from '@walletconnect/types'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Button } from 'rsuite'

import { responsiveWidthM } from 'util/const'
import { enableWalletConnect } from 'util/web3'

import WalletConnectImage from '../images/icon-walletconnect.svg'

interface WalletConnectProps {
  isMobileBrowser: boolean
  setLetsGo: React.Dispatch<React.SetStateAction<boolean>>
  showJustTrustWalletWC: boolean
}

const WalletConnectTrustWalletQRCodeOptions: IWalletConnectProviderOptions = {
  qrcodeModalOptions: {
    mobileLinks: ['trust']
  }
}

const WalletConnectButtonWrapper = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    display: block;
  }

  margin-top: 10px;
  display: inline-block;
`

const WalletConnectButton = styled(Button)`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    margin-left: 0;
    margin-top: 10px;
  }

  background: rgb(250, 46, 83);
  border-radius: 22px;
  height: 44px;
  width: 230px;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  letter-spacing: 0px;
  text-align: center;
`

const WalletConnectIcon = styled.img.attrs({ src: WalletConnectImage })`
  width: 30px;
  height: 18px;
  margin-top: -3px;
  margin-right: 10px;
`

const WalletConnectButtonComponent = ({ isMobileBrowser, setLetsGo, showJustTrustWalletWC }: WalletConnectProps) => {
  useEffect(() => {
    const checkWalletConnect = async () => {
      if (showJustTrustWalletWC) {
        const wallets = await enableWalletConnect(WalletConnectTrustWalletQRCodeOptions)
        if (wallets.length) {
          setLetsGo(true)
        }
      }
    }

    checkWalletConnect()
  }, [setLetsGo, showJustTrustWalletWC])

  let walletConnectOptions: IWalletConnectProviderOptions
  if (showJustTrustWalletWC) {
    walletConnectOptions = WalletConnectTrustWalletQRCodeOptions
  }

  return (
    <WalletConnectButtonWrapper>
      <WalletConnectButton
        appearance='primary'
        onClick={async () => {
          const wallets = await enableWalletConnect(walletConnectOptions)
          if (wallets.length) {
            setLetsGo(true)
          }
        }}
        style={{ marginTop: isMobileBrowser ? 0 : undefined }}
      >
        <WalletConnectIcon />
        WalletConnect
      </WalletConnectButton>
    </WalletConnectButtonWrapper>
  )
}

export default WalletConnectButtonComponent
