import { StakingSummary } from '@stormx/calculate-staking'
import { atom, selector } from 'recoil'

import { parseHashAuth, AuthState, DEFAULT_ACCOUNT, DEFAULT_AUTH } from 'util/auth'
import store from 'util/store'

export interface StakingState {
  notLoaded: boolean
  summary?: StakingSummary
}

export interface Rates {
  loading: boolean
  ATH: number
  STMX: number
  STMX_V2: number
}

export enum Web3StateType {
  NONE = 0,
  NATIVE = 1,
  WALLET_CONNECT = 2
}

export interface Web3State {
  connected: boolean
  initialized: boolean
  networkId: number
  type: Web3StateType
  wallet: string
}

export const DEFAULT_STAKING: StakingState = {
  notLoaded: true
}

export const DEFAULT_WEB3: Web3State = {
  connected: false,
  initialized: false,
  networkId: 0,
  type: Web3StateType.NONE,
  wallet: ''
}

export const DEFAULT_RATES: Rates = {
  loading: true,
  ATH: 0,
  STMX: 0,
  STMX_V2: 0
}

export const authSelector = selector({
  key: 'authSelector',
  get: async () => {
    const hashAuth = parseHashAuth()
    const auth = (await store.getItem('auth')) as AuthState
    if (hashAuth.signedIn) {
      await store.setItem('auth', hashAuth)
      return hashAuth
    } else if (!auth) {
      await store.setItem('auth', DEFAULT_AUTH)
    }
    return auth
  },
  set: ({ get, set }, newValue) => {
    const previousValue = get(authState)
    const updatedValue = { ...previousValue, ...newValue }
    store.setItem('auth', updatedValue)
    set(authState, updatedValue)
  }
})

export const accountState = atom({
  key: 'accountState',
  default: DEFAULT_ACCOUNT
})

export const authState = atom({
  key: 'authState',
  default: DEFAULT_AUTH
})

export const countryState = atom({
  key: 'countryState',
  default: ''
})

export const debugState = atom({
  key: 'debugState',
  default: ''
})

export const stakingState = atom({
  key: 'stakingState',
  default: DEFAULT_STAKING
})

export const web3State = atom({
  key: 'web3State',
  default: DEFAULT_WEB3
})

export const ratesState = atom({
  key: 'ratesState',
  default: DEFAULT_RATES
})

export const termsOfUseState = atom({
  key: 'termsOfUseState',
  default: 1
})
