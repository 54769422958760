import styled from 'styled-components'
import { withModifiers } from 'util/styles'
import cooldownImage from 'components/staking/images/tokens-locked.png'
import rightArrow from 'components/staking/images/arrow-right-white.png'

interface TextProps {
  bold?: boolean
  button?: boolean
  description?: boolean
}

export const RightArrow = styled.img.attrs({
  src: rightArrow
})`
  width: 24px;
  height: 24px;
  margin-right: 18px;
`

export const ContinueButton = styled.button`
  width: 260px;
  height: 44px;
  background: rgb(253, 46, 83);
  border-radius: 22px;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 20px;

  &:hover {
    opacity: 0.5;
  }
`

export const EmptyDiv = styled.div`
  width: 24px;
  height: 24px;
  margin-left: 18px;
`

export const CooldownImage = styled.img.attrs({
  src: cooldownImage
})`
  width: 160px;
  height: 160px;
  margin: 0 auto;
  display: flex;
  margin-top: 20px;
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('description')`
    color: rgb(29, 29, 29);
    font-size: 15px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
    justify-content: center;
  `};

  ${withModifiers('bold')`
    font-weight: bold;
  `};

  ${withModifiers('button')`
    color: rgb(254, 255, 255);
    font-size: 14px;
    font-family: Montserrat;
    font-weight: bold;
    text-align: center;
    line-height: 18px;
    cursor: pointer;
  
    &:hover {
      opacity: 0.5;
    }
  `};
`

export const ModalHeader = styled.div`
  color: rgb(29, 29, 29);
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 800;
  text-align: center;
  line-height: 34px;
  margin-top: 25px;
  margin-bottom: 18px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: center;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
`
