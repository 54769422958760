import ThemedModal from '../../ThemedModal'
import { Modal } from 'rsuite'
import { isEmpty } from 'lodash'
import { isStaging } from 'util/const'
import {
  Text,
  Stormy,
  Container,
  CheckIcon,
  Quantstamp,
  LoadingIcon,
  ModalHeader,
  TriangleIcon,
  RowContainer
} from './components/index'

interface TransactionStatusDialogProps {
  method: string
  methodTransactionHash: string
  transactionLoading: boolean | null
  showTransactionStatusDialog: boolean
  increaseAllowanceLoading: boolean | null
  setShowTransactionStatusDialog: (show: boolean) => void
}

const TransactionStatusDialog = ({
  method,
  transactionLoading,
  methodTransactionHash,
  increaseAllowanceLoading,
  showTransactionStatusDialog,
  setShowTransactionStatusDialog
}: TransactionStatusDialogProps) => {
  return (
    <ThemedModal overflow={false} show={showTransactionStatusDialog}>
      <Modal.Header onHide={() => setShowTransactionStatusDialog(false)}>
        <ModalHeader></ModalHeader>
      </Modal.Header>
      <Container>
        <Stormy />
        <Text text>Hold tight,</Text>
        <Text text>
          initiating the {method === 'convert' ? 'swap' : method === 'cancel' ? 'cancel and restake' : method}...
        </Text>

        <Text steps>Steps to {method === 'cancel' ? 'cancel and restake' : method} your tokens:</Text>

        <RowContainer completed={increaseAllowanceLoading === false}>
          {increaseAllowanceLoading === null ? (
            <TriangleIcon />
          ) : Boolean(increaseAllowanceLoading) === true ? (
            <LoadingIcon />
          ) : (
            <CheckIcon />
          )}
          <Text item>Confirm the INCREASE ALLOWANCE wallet transaction</Text>
        </RowContainer>

        <RowContainer completed={transactionLoading === false}>
          {transactionLoading === null ? (
            <TriangleIcon />
          ) : Boolean(transactionLoading) === true ? (
            <LoadingIcon />
          ) : (
            <CheckIcon />
          )}
          <Text item>
            {method === 'cancel' ? 'Cancel' : 'Confirm'} the {method === 'cancel' ? 'UNSTAKE' : method.toUpperCase()}{' '}
            wallet transaction
          </Text>
        </RowContainer>

        <Text
          hash
          onClick={() => {
            window.open(`https://${isStaging ? 'goerli.' : ''}etherscan.io/tx/${methodTransactionHash}`)
          }}
          transparent={isEmpty(methodTransactionHash)}
        >
          View the transaction on etherscan
        </Text>

        <Text audited>Smart contract audited by</Text>
        <Quantstamp />
      </Container>
    </ThemedModal>
  )
}

export default TransactionStatusDialog
