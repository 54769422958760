import styled from 'styled-components'
import { Table } from 'rsuite'
import { withModifiers } from 'util/styles'
import { responsiveWidthM } from 'util/const'

interface RowContainerProps {
  column?: boolean
  withSpaceBetween?: boolean
}

interface HeaderProps {
  isSmallScreen?: boolean
}

export const Wrapper = styled.div`
  min-height: 775px;
  background: rgb(255, 255, 255);
  border-radius: 32px;
  border: 1px solid rgb(232, 232, 232);
  padding: 40px 30px;
  margin-top: 30px;
  margin-left: 30%;
  margin-right: 30%;

  @media only screen and (max-width: ${responsiveWidthM}px) {
    margin-left: 2.6%;
    margin-right: 2.6%;
  }
`

export const RowContainer = styled.div<RowContainerProps>`
  flex-direction: row;
  align-items: center;
  display: flex;

  ${withModifiers('withSpaceBetween')`
    justify-content: space-between;
  `};

  ${withModifiers('column')`
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  `};
`

export const Header = styled.div<HeaderProps>`
  color: #1d1d1d;
  font-family: Montserrat;
  font-size: 17px;
  font-weight: bold;
  height: 22px;
  line-height: 22px;

  ${withModifiers('isSmallScreen')`
    margin-bottom: 12px;
  `};
`

export const YourRankingWrapper = styled.div`
  background: rgb(255, 255, 255);
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
`

export const YourRankingHeader = styled.div`
  color: rgb(86, 96, 105);
  font-size: 11px;
  font-family: Montserrat;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  line-height: 14px;
  margin-top: 45px;
  margin-bottom: -10px;
`

export const ThemedTable = styled(Table)`
  margin-top: 29px;

  .rs-table-row {
    border: 1px solid transparent;
    min-width: auto !important;
  }

  .rs-table-cell-content {
    padding-right: 0;
    padding-left: 0;
    direction: rtl;

    font-size: 15px;
    font-family: Adelle;
    font-weight: normal;
  }

  .rs-table-row-even {
    background: #eaedef;
    border-radius: 0px;
    box-shadow: 0px 0.5px 0px 0px rgba(0, 0, 0, 0.07);

    .rs-table-cell {
      background: #eaedef;
    }
  }

  .rs-table-row-highlighted {
    border: 1px solid rgb(250, 46, 83);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05), 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  }

  .rs-table-header-row-wrapper .rs-table-row {
    background: linear-gradient(0deg, rgb(203, 0, 92) -99%, rgb(250, 46, 83) 100%);
    border-radius: 8px 8px 0px 0px;
    height: 40px;
    width: 100%;

    .rs-table-cell {
      background: transparent;
      color: rgb(255, 255, 255);
    }
  }

  .rs-table-cell {
    color: rgb(29, 29, 29);
    font-size: 15px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
  }
`

export const StakeSomeTokens = styled.div`
  color: rgb(38, 38, 38);
  font-family: Adelle;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0px;
  margin-top: 15px;
`
