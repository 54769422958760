import React, { Fragment } from 'react'
import ThemedModal from '../../ThemedModal'
import { Modal } from 'rsuite'
import { useRecoilValue } from 'recoil'
import { ratesState, web3State, Web3StateType } from 'util/state'
import { isStaging, STORMX_V2_TOKEN_ADDRESS } from 'util/const'
import { Text, Button, Container, ModalHeader, TokenSwapFail, TokenSwapSuccess } from './components/index'

interface SwapStatusDialogProps {
  error: string
  amount: string
  success: boolean
  swapTransaction: string
  showSwapStatusDialog: boolean
  setShowStakeDialog: React.Dispatch<React.SetStateAction<boolean>>
  setShowSwapStatusDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const SwapStatusDialog = ({
  error,
  amount,
  success,
  swapTransaction,
  setShowStakeDialog,
  showSwapStatusDialog,
  setShowSwapStatusDialog
}: SwapStatusDialogProps) => {
  const rates = useRecoilValue(ratesState)
  const web3state = useRecoilValue(web3State)
  const isMetamaskConnected = web3state.type === Web3StateType.NATIVE

  return (
    <ThemedModal overflow={false} show={showSwapStatusDialog}>
      <Modal.Header onHide={() => setShowSwapStatusDialog(false)}>
        {success ? <TokenSwapSuccess /> : <TokenSwapFail />}
        <ModalHeader>{success ? 'Token Swap Complete!' : 'Oops, your transaction failed'}</ModalHeader>
      </Modal.Header>
      <Container>
        <Text description>
          {success
            ? `You have successfully swapped ${amount} STMX tokens.`
            : error
            ? error
            : 'Something went please, please try again.'}
        </Text>

        {success && <Text description>Start staking your STMX today and earn {rates.STMX_V2}% p.a.</Text>}

        {!success && swapTransaction && (
          <Text
            seeError
            onClick={() => {
              window.open(`https://${isStaging ? 'goerli.' : ''}etherscan.io/tx/${swapTransaction}`)
            }}
          >
            View failed transaction
          </Text>
        )}

        <Button
          onClick={() => {
            if (!success) {
              setShowSwapStatusDialog(false)
            } else {
              setShowSwapStatusDialog(false)
              setShowStakeDialog(true)
            }
          }}
        >
          <Text button>{success ? 'Stake my STMX tokens' : 'Go back and retry'}</Text>
        </Button>

        <Text cantSeeTokens>{success ? `Can't see the new tokens in your wallet?` : 'Still having problems?'}</Text>

        <Text
          addToken
          onClick={async () => {
            if (success) {
              if (isMetamaskConnected) {
                await window.ethereum.request({
                  method: 'wallet_watchAsset',
                  params: {
                    type: 'ERC20',
                    options: {
                      address: STORMX_V2_TOKEN_ADDRESS,
                      symbol: 'STMX',
                      decimals: 18,
                      image: 'https://assets.coingecko.com/coins/images/1369/small/StormX.png?1603113002'
                    }
                  }
                })
              } else {
                window.open('https://stormx.zendesk.com/knowledge/articles/9749657267479/en-us?brand_id=5003627')
              }
            } else {
              window.open('https://help.stormx.io/hc/en-us/requests/new')
            }
          }}
        >
          {success && (
            <Fragment>
              {isMetamaskConnected ? 'Add STMX token to MetaMask' : 'How to add STMX token to your wallet'}
            </Fragment>
          )}

          {!success && 'Get in touch with support'}
        </Text>
      </Container>
    </ThemedModal>
  )
}

export default SwapStatusDialog
