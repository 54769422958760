import styled from 'styled-components'
import { withModifiers } from 'util/styles'
import quantstamp from 'components/staking/images/quantstamp.png'
import stormy from 'components/staking/images/stormy.gif'
import triangle from 'components/staking/images/triangle.png'
import check from 'components/staking/images/check.png'
import loading from 'components/staking/images/loading.png'

interface TextProps {
  text?: boolean
  item?: boolean
  hash?: boolean
  steps?: boolean
  audited?: boolean
  transparent?: boolean
}

interface RowContainerProps {
  completed?: boolean
}

export const ModalHeader = styled.div`
  color: rgb(29, 29, 29);
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 800;
  text-align: center;
  line-height: 34px;
  margin-top: 25px;
  margin-bottom: 9px;
`

export const Stormy = styled.img.attrs({
  src: stormy
})`
  width: 97px;
  height: 104px;
  justify-content: center;
  display: flex;
  align-itens: center;
  margin: 0 auto;
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('text')`
    color: rgb(29, 29, 29);
    font-size: 14px;
    font-family: Adelle;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 20px;
  `};

  ${withModifiers('item')`
    color: rgb(29, 29, 29);
    font-size: 14px;
    font-family: Adelle;
    font-weight: normal;
    letter-spacing: 0.2px;
    line-height: 20px;
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 1px;
  `};

  ${withModifiers('steps')`
    color: rgb(253, 46, 83);
    font-size: 14px;
    font-family: Adelle;
    font-weight: bold;
    letter-spacing: 0.2px;
    line-height: 20px;
    width: 80%;
    margin-left: 51px;
    text-align: left;
    margin-top: 48px;
  `};

  ${withModifiers('audited')`
    color: rgb(86, 96, 105);
    font-size: 12px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
  `};

  ${withModifiers('hash')`
    color: rgb(253, 46, 83);
    font-size: 14px;
    font-family: Adelle;
    font-weight: bold;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0.2px;
    text-decoration-line: underline;
    margin-top: 30px;
    margin-bottom: 30px;
    cursor: pointer;

    ${withModifiers('transparent')`
      color: white;
      cursor: default;
    `};

    &:hover {
      opacity: 0.5;
    }
  `};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const RowContainer = styled.div<RowContainerProps>`
  flex-direction: row;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;
  width: 80%;

  ${withModifiers('completed')`
    opacity: 0.5;
  `};
`

export const TriangleIcon = styled.img.attrs({
  src: triangle
})`
  width: 12px;
  height: 12px;
`

export const CheckIcon = styled.img.attrs({
  src: check
})`
  width: 12px;
  height: 12px;
`

export const LoadingIcon = styled.img.attrs({
  src: loading
})`
  width: 12px;
  height: 12px;

  animation: rotation 2s infinite linear;
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`

export const Quantstamp = styled.img.attrs({
  src: quantstamp
})`
  width: 96px;
  height: 18px;
  margin-top: 10px;
`
