import styled from 'styled-components'
import { ButtonGroup } from 'rsuite'
import { withModifiers } from 'util/styles'

interface TextProps {
  time?: boolean
  info?: boolean
  stake?: boolean
  timeSelected?: boolean
  unstakeMessage?: boolean
}

interface TimeButtonProps {
  selected?: boolean
}

export const StakeButton = styled.button`
  width: 260px;
  height: 44px;
  background: rgb(253, 46, 83);
  border-radius: 22px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  margin-bottom: 34px;

  &:hover {
    opacity: 0.5;
  }
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('stake')`
    color: rgb(254, 255, 255);
    font-size: 14px;
    font-family: Montserrat;
    font-weight: bold;
    text-align: center;
    line-height: 18px;
  `};

  ${withModifiers('unstakeMessage')`
    color: rgb(29, 29, 29);
    font-size: 14px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.2px;
    line-height: 20px;
  `};

  ${withModifiers('info')`
    color: rgb(86, 96, 105);
    font-size: 11px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0px;
    display: flex;
  `};

  ${withModifiers('time')`
    color: rgb(29, 29, 29);
    font-size: 13px;
    font-family: Montserrat;
    font-weight: bold;
    text-align: center;
    line-height: 16px;

    &:hover {
      opacity: 0.5;
    }

    ${withModifiers('timeSelected')`
      color: rgb(255, 255, 255);
    `};
  `};

  ${withModifiers('total-loss-title')`
    color: rgb(86, 96, 105);
    font-size: 11px;
    font-family: Montserrat;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    line-height: 14px;
  `};

  ${withModifiers('total-loss-number')`
    color: rgb(29, 29, 29);
    font-size: 17px;
    font-family: SFMono;
    font-weight: bold;
    text-align: center;
  `};
`

export const PercentagesGroup = styled(ButtonGroup)`
  height: 40px;
  margin-bottom: 34px;
  justify-content: center;
`

export const PercentageButton = styled.div<TimeButtonProps>`
  width: 60px;
  height: 34px;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 17px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  margin-right: 10px;

  &:hover {
    opacity: 0.5;
  }

  ${withModifiers('selected')`
    background: #2b2969;
  `};
`
