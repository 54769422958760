import React, { Fragment } from 'react'
import { Col, Grid, Row } from 'rsuite'
import styled from 'styled-components'

import { AccountState } from 'util/auth'
import { responsiveWidthM } from 'util/const'

import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import TopSection from './TopSection'

import DashImage from '../images/img-dash.png'

interface InstructionsProps {
  authAccount: AccountState
  isAccountCreated: boolean
  isMobileBrowser: boolean
  isWalletConnected: boolean
  setStartStaking: React.Dispatch<React.SetStateAction<boolean>>
}

const Wrapper = styled.div`
  &:before {
    @media only screen and (max-width: ${responsiveWidthM}px) {
      left: 30px;
    }

    background: transparent url(${DashImage}) 0 0 repeat-y;
    width: 2px;
    position: absolute;
    top: 40px;
    bottom: 20px;
    left: 20px;
    display: inline-block;
    content: ' ';
    z-index: -1;
  }

  position: relative;
`

const Instructions = ({
  authAccount,
  isAccountCreated,
  isMobileBrowser,
  isWalletConnected,
  setStartStaking
}: InstructionsProps) => {
  return (
    <Fragment>
      <TopSection />
      <Grid>
        <Row>
          <Col>
            <Wrapper>
              <Step1 isDone={isAccountCreated} isMobileBrowser={isMobileBrowser} previousDone showForm />
              <Step2
                accountId={authAccount?.account?.id}
                isDone={isWalletConnected}
                isLoading={authAccount.loading}
                isMobileBrowser={isMobileBrowser}
                previousDone={isAccountCreated}
              />
              <Step3
                accountId={authAccount?.account?.id}
                balance={authAccount?.account?.stormxRewards?.balance}
                email={authAccount?.account?.email as string}
                isDone={false}
                isLoading={authAccount.loading}
                previousDone={isAccountCreated && isWalletConnected}
                setStartStaking={setStartStaking}
                walletAddress={authAccount?.account?.stormxRewards?.wallet}
              />
            </Wrapper>
          </Col>
        </Row>
      </Grid>
    </Fragment>
  )
}

export default Instructions
