import styled from 'styled-components'

interface SelectedTokenProps {
  selected: boolean
  apy?: boolean
}

export const RowContainer = styled.div`
  flex-direction: column;
  align-items: flex-start;
  display: flex;
`

export const ToggleWrapper = styled.div`
  width: 200px;
  text-align: center;
  padding: 0px;
  height: 34px;
  background: #eaedef;
  border-radius: 30px;
  flex-direction: row;
  align-items: center;
  display: flex;
  justify-content: center;
`

export const ToggleCurrency = styled.label<SelectedTokenProps>`
  color: ${(props) => (props.selected ? 'rgb(250, 46, 83)' : '#566069')};
  font-size: 14px;
  font-family: ${(props) => (props.apy ? 'Adelle' : 'Montserrat')};
  font-weight: 600;
  text-align: center;
  line-height: 18px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: none;
  }
`

export const ToggleCurrencyItem = styled.div<SelectedTokenProps>`
  width: 246px;
  height: 31px;
  border-width: 1px;
  border-style: solid;
  background: ${(props) => (props.selected ? 'rgb(255, 255, 255)' : 'transparent')};
  border-color: ${(props) => (props.selected ? 'rgb(232, 232, 232)' : 'transparent')};
  margin-right: ${(props) => (props.selected ? 1.5 : 0)}px;
  margin-left: ${(props) => (props.selected ? 1.5 : 0)}px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ selected }) =>
    selected &&
    `
    box-shadow: inset 0px -1px 0px 0px rgba(116, 111, 127, 0.05), 0px 3px 1px 0px rgba(0, 0, 0, 0.04), 0px 3px 4px 0px rgba(0, 0, 0, 0.12);
  `}

  &:hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: none;
  }
`
