// fixed constants
const WEB_PLATFORM_AFF_ID = 4

// imported from .env
const API_URL = process.env.REACT_APP_API_URL as string
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as string
const EXTENSION_URL = process.env.REACT_APP_EXTENSION_URL as string
const INFURA_TOKEN = process.env.REACT_APP_INFURA_TOKEN as string
const MOONPAY_API_KEY = process.env.REACT_APP_MOONPAY_API_KEY as string
const STORMX_TOKEN_ADDRESS = process.env.REACT_APP_STORMX_TOKEN_ADDRESS as string
const STORMX_V2_TOKEN_ADDRESS = process.env.REACT_APP_STORMX_V2_TOKEN_ADDRESS as string
const STORMX_V2_STAKING_ADDRESS = process.env.REACT_APP_STORMX_V2_STAKING_ADDRESS as string
const ATHENS_TOKEN_ADDRESS = process.env.REACT_APP_ATHENS_TOKEN_ADDRESS as string
const SWAP_CONTRACT_ADDRESS = process.env.REACT_APP_SWAP_CONTRACT_ADDRESS as string
const ZENDESK_KEY = process.env.REACT_APP_ZENDESK_KEY as string
const SWAP_URL = process.env.REACT_APP_SWAP_URL as string
const COOLDOWN_PERIOD = parseInt(process.env.REACT_APP_COOLDOWN_PERIOD as string) as number
const WALLETCONNECT_ID = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID as string

// other constants
const isProduction = ENVIRONMENT === 'production'
const isStaging = ENVIRONMENT === 'staging'

const iOSApp = 'https://itunes.apple.com/app/storm-play/id1420545397'
const androidApp = 'https://play.google.com/store/apps/details?id=com.cakecodes.bitmaker'
const openMobileAppDeeplink = isStaging ? 'https://hhc4g.app.link/RMTeQZ967kb' : 'https://stormx.app.link/3kC4DDe77kb'

const responsiveWidthSBase = 480
const responsiveWidthMBase = 992
const responsiveWidthLBase = 1200

const responsiveWidthS = responsiveWidthSBase - 1
const responsiveWidthM = responsiveWidthMBase - 1
const responsiveWidthL = responsiveWidthLBase - 1

export {
  WEB_PLATFORM_AFF_ID,
  API_URL,
  ENVIRONMENT,
  WALLETCONNECT_ID,
  EXTENSION_URL,
  INFURA_TOKEN,
  MOONPAY_API_KEY,
  STORMX_TOKEN_ADDRESS,
  ATHENS_TOKEN_ADDRESS,
  STORMX_V2_TOKEN_ADDRESS,
  STORMX_V2_STAKING_ADDRESS,
  SWAP_CONTRACT_ADDRESS,
  ZENDESK_KEY,
  SWAP_URL,
  COOLDOWN_PERIOD,
  isProduction,
  isStaging,
  responsiveWidthS,
  responsiveWidthM,
  responsiveWidthL,
  iOSApp,
  androidApp,
  openMobileAppDeeplink
}
