import styled from 'styled-components'
import ExtensionEdgeImage from 'components/staking/images/extension-edge.svg'
import ExtensionChromeImage from 'components/staking/images/extension-chrome.svg'
import QRCodeStgImage from 'components/staking/images/qrcode-open-app-stg.jpg'
import QRCodeProdImage from 'components/staking/images/qrcode-open-app-prod.jpg'
import GooglePlayButtonImage from 'components/staking/images/google-play-button.svg'
import AppleStoreButtonImage from 'components/staking/images/apple-store-button.svg'
import { isStaging } from 'util/const'

export const ModalContent = styled.div`
  color: rgb(44, 37, 60);
  font-family: Adelle;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.2px;
  text-align: center;
  margin: 15px;
`

export const ModalHeader = styled.div`
  &:before {
    content: ' ';
    background-color: #dfe1e2;
    display: inline-block;
    height: 1px;
    position: absolute;
    right: 80px;
    bottom: 0;
    left: 100px;
  }

  color: rgb(44, 37, 60);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 15px;
  text-align: center;
  margin-top: 25px;
  position: relative;
  box-sizing: content-box;
`

export const BrowserEdge = styled.img.attrs({ src: ExtensionEdgeImage })`
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const BrowserChrome = styled.img.attrs({ src: ExtensionChromeImage })`
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const QRCode = styled.img.attrs({
  src: isStaging ? QRCodeStgImage : QRCodeProdImage
})`
  width: 100px;
  margin-top: 15px;
`

export const GooglePlayButton = styled.img.attrs({ src: GooglePlayButtonImage })`
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const AppleStoreButton = styled.img.attrs({ src: AppleStoreButtonImage })`
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`

export const RowContainer = styled.div`
  flex-direction: column;
  align-items: center;
  display: flex;
`
