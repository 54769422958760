import { EthereumProvider } from '@walletconnect/ethereum-provider'
import { useEffect, useState } from 'react'
import { useRecoilState } from 'recoil'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'

import { getNetworkId, getWeb3AdapterCached, getWalletAddress, isConnected } from 'util/web3'
import { web3State, DEFAULT_WEB3, Web3StateType } from 'util/state'

interface Web3SubscriptionProps {
  onDisconnect: () => any
}

const Web3Subscription = ({ onDisconnect }: Web3SubscriptionProps) => {
  const [web3state, setWeb3State] = useRecoilState(web3State)
  const [initialized, setInitialized] = useState(false)

  const [connected, setConnected] = useState(DEFAULT_WEB3.connected)
  const [networkId, setNetworkId] = useState(DEFAULT_WEB3.networkId)
  const [type, setType] = useState(DEFAULT_WEB3.type)
  const [wallet, setWallet] = useState(DEFAULT_WEB3.wallet)

  useEffect(() => {
    const onAccountChanged = (accounts: string[]) => setWallet(accounts[0].toLowerCase())
    const onChainChanged = (chainId: string) => setNetworkId(+chainId)
    const onWalletConnectDisconnect = () => onDisconnect() && setConnected(false)

    const bindEvents = (on = true) => {
      const web3 = getWeb3AdapterCached()
      if (web3) {
        if (web3.currentProvider instanceof EthereumProvider) {
          web3.currentProvider[on ? 'on' : 'off']('accountsChanged', onAccountChanged)
          web3.currentProvider[on ? 'on' : 'off']('chainChanged', onChainChanged)
          web3.currentProvider[on ? 'on' : 'off']('disconnect', onWalletConnectDisconnect)
          if (type !== Web3StateType.WALLET_CONNECT) {
            setType(Web3StateType.WALLET_CONNECT)
          }
        } else if (window.ethereum && isFunction(window.ethereum[on ? 'on' : 'off'])) {
          window.ethereum[on ? 'on' : 'off']('accountsChanged', onAccountChanged)
          window.ethereum[on ? 'on' : 'off']('chainChanged', onChainChanged)
          if (type !== Web3StateType.NATIVE) {
            setType(Web3StateType.NATIVE)
          }
        }
      }
    }

    const checkWeb3Adapter = (): boolean => {
      if (!initialized) {
        const web3 = getWeb3AdapterCached()

        if (web3) {
          setInitialized(true)

          bindEvents(true)

          Promise.all([
            (async () => setConnected(await isConnected()))(),
            (async () => setNetworkId(await getNetworkId()))(),
            (async () => setWallet(await getWalletAddress()))()
          ])

          return true
        }
      }
      return false
    }

    const oldValues = cloneDeep(web3state)
    const newValues = {
      connected,
      initialized,
      networkId,
      type,
      wallet
    }
    if (!connected) {
      newValues.initialized = DEFAULT_WEB3.initialized
      newValues.networkId = DEFAULT_WEB3.networkId
      newValues.type = DEFAULT_WEB3.type
      newValues.wallet = DEFAULT_WEB3.wallet
    }
    const updatedValues = {
      ...oldValues,
      ...newValues
    }
    if (!isEqual(oldValues, updatedValues)) {
      setWeb3State(updatedValues)
    }

    if (!initialized) {
      if (!checkWeb3Adapter()) {
        const interval = setInterval(() => {
          if (checkWeb3Adapter()) {
            clearInterval(interval)
          }
        }, 1000)
      }
    }

    return () => {
      const web3 = getWeb3AdapterCached()
      if (initialized && web3) {
        bindEvents(false)
      }
    }
  }, [
    connected,
    initialized,
    networkId,
    onDisconnect,
    setConnected,
    setNetworkId,
    setType,
    setWallet,
    setWeb3State,
    type,
    wallet,
    web3state
  ])

  return null
}

export default Web3Subscription
