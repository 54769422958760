import styled from 'styled-components'
import cooldownWaitingIcon from 'components/staking/images/cooldown-waiting.png'
import cooldownReadyIcon from 'components/staking/images/cooldown-ready.png'
import { responsiveWidthM } from 'util/const'
import { withModifiers } from 'util/styles'

interface TextProps {
  unstakeNow?: boolean
  availability?: boolean
  isSmallScreen?: boolean
}

interface RowContainerProps {
  center?: boolean
  column?: boolean
  statistics?: boolean
  withSpaceBetween?: boolean
}

interface ButtonProps {
  unstakeNow?: boolean
}

interface TopBottomContainerProps {
  unstakeNow?: boolean
}

export const Container = styled.div`
  width: auto;
  border: 1px solid rgb(232, 232, 232);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.08);
  border-radius: 18px;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 80px;
  margin-bottom: -40px;

  @media only screen and (max-width: ${responsiveWidthM}px) {
    margin-left: 2.6%;
    margin-right: 2.6%;
  }
`

export const Button = styled.div<ButtonProps>`
  width: 200px;
  height: 44px;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(232, 232, 232);
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  display: flex;

  @media only screen and (max-width: ${responsiveWidthM}px) {
    margin-top: 19px;
  }

  ${withModifiers('unstakeNow')`
    width: 250px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  `};
`

export const RowContainer = styled.div<RowContainerProps>`
  flex-direction: row;
  display: flex;
  justify-content: center;

  ${withModifiers('column')`
    flex-direction: column;
  `};

  ${withModifiers('withSpaceBetween')`
    justify-content: space-between;
    align-items: center;
  `};

  ${withModifiers('center')`
    align-items: center;
    justify-content: center;
  `};
`

export const IconContainer = styled.div`
  width: 186px;

  @media only screen and (max-width: ${responsiveWidthM}px) {
    justify-content: center;
    align-itens: center;
    display: flex;
    margin-bottom: 17px;
  }
`

export const CooldownWaitingIcon = styled.img.attrs({
  src: cooldownWaitingIcon
})`
  width: 36px;
  height: 36px;

  @media only screen and (max-width: ${responsiveWidthM}px) {
    width: 24px;
    height: 24px;
  }
`

export const CooldownReadyIcon = styled.img.attrs({
  src: cooldownReadyIcon
})`
  width: 36px;
  height: 36px;

  @media only screen and (max-width: ${responsiveWidthM}px) {
    width: 24px;
    height: 24px;
  }
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('availability')`
    color: rgb(253, 46, 83);
    font-size: 14px;
    font-family: Montserrat;
    font-weight: bold;
    text-align: center;
    line-height: 18px;

    ${withModifiers('unstakeNow')`
      cursor: pointer;

      &:hover {
        opacity: 0.5;
      }
    `};
  `};

  ${withModifiers('tokens-in-cooldown-message')`
    color: rgb(29, 29, 29);
    font-size: 11px;
    font-family: Montserrat;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    line-height: 14px;
  `};

  ${withModifiers('tokens-in-cooldown')`
    color: rgb(29, 29, 29);
    font-size: 17px;
    font-family: SFMono;
    font-weight: bold;
    text-align: center;
  `};

  ${withModifiers('mind-changed')`
    color: rgb(86, 96, 105);
    font-size: 11px;
    font-family: Montserrat;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.8px;
    line-height: 14px;
    margin-bottom: 7px;
  `};

  ${withModifiers('mind-changed-explanation')`
    color: rgb(29, 29, 29);
    font-size: 15px;
    font-family: Adelle;
    font-weight: normal;
    line-height: 22px;
    text-align: center;
  `};

  ${withModifiers('cancel-cooldown')`
    color: rgb(250, 46, 83);
    font-size: 15px;
    font-family: Adelle;
    font-weight: normal;
    text-align: right;
    letter-spacing: 0px;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }

    @media only screen and (max-width: ${responsiveWidthM}px) {
      margin-top: 10px;
    }
  `};
`

export const TopContainer = styled.div<TopBottomContainerProps>`
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 32px;
  padding-right: 30px;
  background: rgb(255, 212, 0);
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;

  ${withModifiers('unstakeNow')`
    background: #38f1d1;
  `};

  @media only screen and (max-width: ${responsiveWidthM}px) {
    flex-direction: column;
  }
`

export const BottomContainer = styled.div`
  background: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 32px;
  padding-right: 30px;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;

  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: ${responsiveWidthM}px) {
    flex-direction: column;
    justify-content: center;
  }
`
