import React, { useEffect, Fragment } from 'react'
import { Loader } from 'rsuite'
import { TOKENS } from 'util/web3'
import { get, isEmpty } from 'lodash'
import { useRecoilValue } from 'recoil'
import { responsiveWidthM } from 'util/const'
import { numberWithCommas } from 'util/utils'
import { useMediaQuery } from 'react-responsive'
import { ratesState, stakingState } from 'util/state'
import Earnings, { StakingBalance } from '../earnings/index'
import {
  Text,
  Line,
  Column,
  AthLogo,
  StmxLogo,
  Container,
  SectionRow,
  LeftContent,
  StakeButton,
  RowContainer,
  RightContent,
  VerticalLine,
  StatisticItem,
  BottomContent
} from './components/index'

interface TokenCardProps {
  token: string
  penalty: number
  first?: boolean
  loading: boolean
  firstLoad: boolean
  swapToken: () => any
  cooldownAmount: string
  balance: StakingBalance
  error: boolean | string
  isMobileBrowser: boolean
  showStakeStatusDialog: boolean
  setTokenSelected: React.Dispatch<React.SetStateAction<string>>
  setShowStakeDialog: React.Dispatch<React.SetStateAction<boolean>>
  setShowUnstakeDialog: React.Dispatch<React.SetStateAction<boolean>>
  setShowCooldownDialog: React.Dispatch<React.SetStateAction<boolean>>
}

const Card = ({
  first,
  error,
  token,
  penalty,
  balance,
  loading,
  firstLoad,
  swapToken,
  cooldownAmount,
  isMobileBrowser,
  setTokenSelected,
  setShowStakeDialog,
  setShowUnstakeDialog,
  showStakeStatusDialog,
  setShowCooldownDialog
}: TokenCardProps) => {
  const tokenFormatted = token.replace(' ', '_')
  const rates = useRecoilValue(ratesState)
  const stakingstate = useRecoilValue(stakingState)
  const stakingAccount = {
    ...stakingstate,
    summary: get(stakingstate, `summary.${tokenFormatted}`, undefined)
  }

  const isSmallScreen = useMediaQuery({
    query: `(max-width: ${responsiveWidthM}px)`
  })

  useEffect(() => {
    if (showStakeStatusDialog) {
      setShowStakeDialog(false)
    }
  }, [showStakeStatusDialog, setShowStakeDialog])

  const renderStatistics = () => {
    const tvl = get(stakingAccount, 'summary.metrics.stakingMetrics.totalBalance', 0)

    const rate = get(rates, `${tokenFormatted}`, 0)

    return (
      <Fragment>
        <StatisticItem>
          <Text statisticTitle>Estimated Staking yield (p.a.)</Text>
          <Text statisticValue>{rate}%</Text>
        </StatisticItem>
        <StatisticItem second>
          <Text statisticTitle>Total Value Locked</Text>

          {isEmpty(stakingAccount) ? (
            <Loader />
          ) : (
            <Text statisticValue>
              {numberWithCommas(parseInt(tvl.toString()))} {token}
            </Text>
          )}
        </StatisticItem>
      </Fragment>
    )
  }

  const renderStakeButton = (isSmallScreen: boolean, token: string) => {
    const disabled = true

    return (
      <StakeButton full={isSmallScreen} disabled={disabled}>
        <Text stake disabled={disabled}>
          Stake unavailable
        </Text>
      </StakeButton>
    )
  }

  return (
    <Fragment>
      {/* {token === TOKENS.STMX && (
        <SwapInfo
          swapToken={swapToken}
          balance={balance}
          setTokenSelected={setTokenSelected}
          setShowUnstakeDialog={setShowUnstakeDialog}
        />
      )} */}

      <Container first={first}>
        <RowContainer>
          <LeftContent full={isSmallScreen}>
            <SectionRow>
              <Column lg={12}>
                <RowContainer>
                  {token === TOKENS.ATH ? <AthLogo /> : <StmxLogo />}

                  <RowContainer column>
                    <Text token>
                      Stake {token} {token === TOKENS.STMX ? '(deprecated)' : ''}
                    </Text>
                    {token === TOKENS.STMX_V2 && (
                      <Text
                        description
                        link
                        onClick={() => {
                          window.open('https://help.stormx.io/hc/en-us/articles/10217541461655')
                        }}
                      >
                        Learn more about STMX V2
                      </Text>
                    )}
                  </RowContainer>
                </RowContainer>
              </Column>

              <Column lg={6} />

              <Column lg={6}>{renderStakeButton(isSmallScreen, token)}</Column>
            </SectionRow>

            <Line isSmallScreen={isSmallScreen} />

            <Earnings
              token={token}
              penalty={penalty}
              balance={balance}
              hasError={!!error}
              loading={!firstLoad && loading}
              cooldownAmount={cooldownAmount}
              isMobileBrowser={isMobileBrowser}
              setTokenSelected={setTokenSelected}
              setShowUnstakeDialog={setShowUnstakeDialog}
              setShowCooldownDialog={setShowCooldownDialog}
            />
          </LeftContent>

          {!isSmallScreen && (
            <Fragment>
              <VerticalLine />

              <RightContent>
                <RowContainer column statistics>
                  {renderStatistics()}
                </RowContainer>
              </RightContent>
            </Fragment>
          )}
        </RowContainer>

        {isSmallScreen && (
          <Fragment>
            <BottomContent>{renderStatistics()}</BottomContent>
          </Fragment>
        )}
      </Container>
    </Fragment>
  )
}

export default Card
