import styled from 'styled-components'
import { withModifiers } from 'util/styles'
import tokenSwapSuccess from 'components/staking/images/token-swap-success.png'
import tokenSwapFail from 'components/staking/images/token-swap-fail.png'

interface TextProps {
  button?: boolean
  addToken?: boolean
  seeError?: boolean
  description?: boolean
  cantSeeTokens?: boolean
}

export const ModalHeader = styled.div`
  color: rgb(29, 29, 29);
  font-size: 22px;
  font-family: Montserrat;
  font-weight: 800;
  text-align: center;
  line-height: 34px;
  margin-top: 25px;
  margin-bottom: 9px;
`

export const TokenSwapFail = styled.img.attrs({
  src: tokenSwapFail
})`
  height: 100%;
  width: 161px;
  justify-content: center;
  display: flex;
  align-itens: center;
  margin: 0 auto;
`

export const TokenSwapSuccess = styled.img.attrs({
  src: tokenSwapSuccess
})`
  height: 100%;
  width: 161px;
  justify-content: center;
  display: flex;
  align-itens: center;
  margin: 0 auto;
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('description')`
    color: rgb(29, 29, 29);
    font-size: 16px;
    font-family: Adelle;
    font-weight: normal;
    text-align: center;
    letter-spacing: 0.15px;
    line-height: 24px;
  `};

  ${withModifiers('button')`
    color: rgb(254, 255, 255);
    font-size: 14px;
    font-family: Montserrat;
    font-weight: bold;
    text-align: center;
    line-height: 18px;
    cursor: pointer;
  `};

  ${withModifiers('cantSeeTokens')`
    color: rgb(86, 96, 105);
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
    text-align: center;
    line-height: 20px;
    margin-top: 36px;
  `};

  ${withModifiers('seeError')`
    color: rgb(250, 46, 83);
    font-size: 15px;
    font-family: Adelle;
    font-weight: normal;
    text-align: right;
    letter-spacing: 0px;
    margin-top: 11px;
    text-decoration-line: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  `};

  ${withModifiers('addToken')`
    color: rgb(250, 46, 83);
    font-size: 14px;
    font-family: Adelle;
    font-weight: normal;
    text-align: right;
    letter-spacing: 0px;
    margin-top: 11px;
    text-decoration-line: underline;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  `};
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Button = styled.div`
  width: 284px;
  height: 44px;
  background: rgb(43, 41, 105);
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  margin-top: 30px;

  &:hover {
    opacity: 0.5;
  }
`
