import styled from 'styled-components'
import { Col, Grid, Row } from 'rsuite'

import { responsiveWidthS, responsiveWidthM, responsiveWidthL } from 'util/const'

import TopLeftImage from '../images/masthead-angle-top-left.svg'
import TopRightImage from '../images/masthead-angle-top-right.svg'
import PlayCirlceImage from '../images/icon-play-circle.svg'
import UnicornImage from '../images/img-unicorn-white.png'

const Background = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    height: 270px;
  }

  @media only screen and (max-width: ${responsiveWidthS}px) {
    height: 235px;
  }

  background: linear-gradient(-90deg, rgb(101, 0, 201) 0%, rgb(250, 46, 83) 100%);
  border-radius: 0px;
  height: 320px;
  position: relative;
  overflow: hidden;
`

const TopLeft = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    max-width: 480px;
  }

  @media only screen and (max-width: ${responsiveWidthS}px) {
    display: none;
  }

  height: 81px;
  width: 497px;
  position: absolute;
  top: 0;
  left: 0;
  background: transparent url(${TopLeftImage}) 0 0 no-repeat;
  z-index: -1;
`

const TopRight = styled.div`
  @media only screen and (max-width: ${responsiveWidthS}px) {
    display: none;
  }

  height: 62px;
  width: 120px;
  position: absolute;
  top: 0;
  right: 0;
  background: transparent url(${TopRightImage}) 0 0 no-repeat;
  z-index: -1;
`

const Inner = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    padding-top: 36px;
  }

  @media only screen and (max-width: ${responsiveWidthS}px) {
    height: 235px;
    padding-top: 21px;
  }

  height: 320px;
  width: 100%;
  position: relative;
  margin: 0 auto;
  padding: 61px 0;
  text-align: center;
`

const WhiteTriangle = styled.div`
  @media only screen and (max-width: ${responsiveWidthM}px) {
    display: none;
  }

  width: 0;
  height: 0;
  border-right: 100vw solid transparent;
  border-bottom: 50px solid white;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
`

const Unicorn = styled.img.attrs({ src: UnicornImage })`
  @media only screen and (max-width: ${responsiveWidthS}px) {
    display: none;
  }

  height: 100px;
  height: 81px;
  margin-top: -35px;
  margin-right: 10px;
`

const Title = styled.div`
  @media only screen and (max-width: ${responsiveWidthS}px) {
    font-size: 30px;
    margin-top: 20px;
  }

  color: rgb(255, 255, 255);
  font-family: Montserrat;
  font-size: 50px;
  font-weight: bold;
  letter-spacing: 0px;
`

const CirclesWrapper = styled.div`
  width: 100%;
  height: 1px;
  margin: -1px auto 0 auto;
  position: relative;
`

const CircleSmaller = styled.div`
  @media only screen and (max-width: ${responsiveWidthL}px) {
    left: 115px;
  }

  @media only screen and (max-width: ${responsiveWidthM}px) {
    left: -115px;
  }

  @media only screen and (max-width: ${responsiveWidthS}px) {
    left: -15px;
  }

  background: linear-gradient(90deg, rgb(250, 46, 83) 0%, rgb(255, 129, 38) 100%);
  border-radius: 50%;
  height: 103px;
  width: 103px;
  position: absolute;
  top: 130px;
  left: 210px;
  opacity: 0.15;
  z-index: -2;
`

const CircleBigger = styled.div`
  @media only screen and (max-width: ${responsiveWidthL}px) {
    left: 155px;
  }

  @media only screen and (max-width: ${responsiveWidthM}px) {
    left: -75px;
  }

  @media only screen and (max-width: ${responsiveWidthS}px) {
    left: 25px;
  }

  background: linear-gradient(90deg, rgb(250, 46, 83) 0%, rgb(255, 129, 38) 100%);
  border-radius: 50%;
  height: 274px;
  width: 274px;
  position: absolute;
  top: -30px;
  left: 250px;
  opacity: 0.15;
  z-index: -2;
`

const Text = styled.div`
  @media only screen and (max-width: ${responsiveWidthS}px) {
    font-size: 16px;
    font-family: Montserrat;
    font-weight: normal;
    line-height: 20px;
    margin-top: 15px;
  }

  color: rgb(255, 255, 255);
  font-family: Adelle;
  font-size: 22px;
  font-weight: normal;
  letter-spacing: 0px;
  line-height: 30px;
  text-align: center;
  margin: 10px 20px;
`

const BackgroundTop = styled.div`
  @media only screen and (max-width: ${responsiveWidthS}px) {
    display: block;
  }

  width: 0;
  height: 0;
  border-left: 100vw solid transparent;
  border-top: 50px solid #4905af;
  opacity: 0.1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -2;
  display: none;
`

const SeeHowStakingWorks = styled.a.attrs({
  href: 'https://youtu.be/ujT0kSH_up0',
  rel: 'noopener noreferrer',
  target: '_blank'
})`
  &:active,
  &:focus,
  &:hover {
    color: rgb(250, 250, 250);
    text-decoration: none;
  }

  background: transparent url(${PlayCirlceImage}) 0 0 no-repeat;
  background-size: 24px 24px;
  color: rgb(250, 250, 250);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 24px;
  height: 24px;
  padding-left: 30px;
  margin-top: 10px;
  display: inline-block;
`

const TopSection = () => {
  return (
    <Background>
      <TopLeft />
      <TopRight />
      <BackgroundTop />
      <WhiteTriangle />

      <Inner>
        <Grid>
          <Row>
            <Col>
              <Title>
                <CirclesWrapper>
                  <CircleSmaller />
                  <CircleBigger />
                </CirclesWrapper>
                <Unicorn />
                Staking
              </Title>
              <Text>Follow the instructions and you'll be staking in no time!</Text>
              <SeeHowStakingWorks>See how staking works</SeeHowStakingWorks>
            </Col>
          </Row>
        </Grid>
      </Inner>
    </Background>
  )
}

export default TopSection
