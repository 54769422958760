import styled from 'styled-components'
import { Loader } from 'rsuite'

export const StakingLoader = styled(Loader)`
  & > .rs-loader {
    height: 60px;
  }

  margin-top: 15px;
  margin-bottom: 15px;
`

export const ModalHeader = styled.div`
  &:before {
    content: ' ';
    background-color: #dfe1e2;
    display: inline-block;
    height: 1px;
    position: absolute;
    right: 80px;
    bottom: 0;
    left: 100px;
  }

  color: rgb(44, 37, 60);
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 800;
  padding-bottom: 29px;
  text-align: center;
  margin-top: 30px;
  position: relative;
  box-sizing: content-box;
`

export const StakingModalWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 30px;
`

export const ModalText = styled.div`
  color: rgb(44, 37, 60);
  font-family: Adelle;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 24px;
  text-align: center;
  margin: 0 20px 10px 20px;
`

export const ModalError = styled(ModalText)`
  color: red;
  font-weight: bold;
`

export const StakingTransaction = styled(ModalText)`
  color: rgb(253, 46, 83);
  font-weight: bold;
`
