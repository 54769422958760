import styled from 'styled-components'
import logo from 'components/staking/images/logo-with-name.png'
import { withModifiers } from 'util/styles'
import { responsiveWidthM } from 'util/const'

interface TextProps {
  link?: boolean
  wallet?: boolean
  buy?: boolean
  disconnect?: boolean
  connectionType?: boolean
}

interface RowContainerProps {
  right?: boolean
  column?: boolean
  wrapper?: boolean
  smallScreen?: boolean
  withSpaceBetween?: boolean
}

export const RowContainer = styled.div<RowContainerProps>`
  flex-direction: row;
  align-items: center;
  display: flex;

  ${withModifiers('wrapper')`
    padding-left: 10%;
    padding-right: 10%;
    height: 100px;
    width: 100%;
    align-items: center;

    @media only screen and (max-width: ${responsiveWidthM}px) {
      padding-left: 2.6%;
      padding-right: 2.6%;
    }

    ${withModifiers('smallScreen')`
      padding-left: 5%;
      padding-right: 5%;
    `};
  `};

  ${withModifiers('column')`
    flex-direction: column;
    align-items: flex-start;
  `};

  ${withModifiers('withSpaceBetween')`
    justify-content: space-between;
  `};

  ${withModifiers('right')`
    margin-left: auto; 
    margin-right: 0;
  `};
`

export const Logo = styled.img.attrs({
  src: logo
})`
  width: 139px;
  height: 48px;
`

export const Text = styled.label<TextProps>`
  -webkit-font-smoothing: antialiased;
  margin-bottom: 0px;

  ${withModifiers('link')`
    color: rgb(29, 29, 29);
    font-size: 15px;
    font-family: Adelle;
    font-weight: normal;
    cursor: pointer;
    margin-right: 30px;

    &:hover {
      opacity: 0.5;
    }
  `};

  ${withModifiers('buy')`
    margin-right: 30px;
  `};

  ${withModifiers('disconnect')`
    color: rgb(250, 46, 83);
    font-size: 13px;
    font-family: Montserrat;
    font-weight: 600;
    text-align: center;
    line-height: 16px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  `};

  ${withModifiers('wallet')`
    color: rgb(29, 29, 29);
    font-size: 14px;
    font-family: Adelle;
    font-weight: normal;
    text-decoration: underline;
    letter-spacing: 0.2px;
    line-height: 20px;
  `};

  ${withModifiers('connectionType')`
    color: rgb(86, 96, 105);
    font-size: 12px;
    font-family: Montserrat;
    font-weight: 500;
    line-height: 20px;
  `};
`
