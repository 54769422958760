import React from 'react'
import BuyStmx from '../../BuyStmx'
import ThemedModal from '../../ThemedModal'
import { Modal } from 'rsuite'
import { BuyStmxWrapper, HeaderModalHeaderBuyStmx } from './components/index'

interface BuyModalProps {
  show: boolean
  email?: string
  accountId?: string
  walletAddress?: string
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const BuyModal = ({ show, email, accountId, setShowModal, walletAddress }: BuyModalProps) => {
  return (
    <ThemedModal overflow={false} show={show}>
      <Modal.Header onHide={() => setShowModal(false)}>
        <HeaderModalHeaderBuyStmx>Buy STMX</HeaderModalHeaderBuyStmx>
      </Modal.Header>
      <Modal.Body>
        <BuyStmxWrapper>
          <BuyStmx accountId={accountId} alwaysCentered email={email} walletAddress={walletAddress} />
        </BuyStmxWrapper>
      </Modal.Body>
    </ThemedModal>
  )
}

export default BuyModal
